export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: number; output: number; }
};

export type AcceptInvitationTokenInput = {
  token: Scalars['String']['input'];
  tokenId: Scalars['ID']['input'];
};

export type AcceptInvitationTokenResult = {
  __typename?: 'AcceptInvitationTokenResult';
  workspaceId: Scalars['ID']['output'];
};

export type AddContactToInstitutionInput = {
  contactId: Scalars['ID']['input'];
  institutionId: Scalars['ID']['input'];
};

export type AddContactToInstitutionResult = {
  __typename?: 'AddContactToInstitutionResult';
  contact: Contact;
  institution: Institution;
};

export type AddOrderCommentInput = {
  comment: Scalars['String']['input'];
  orderId: Scalars['ID']['input'];
};

export type AddOrderCommentResult = {
  __typename?: 'AddOrderCommentResult';
  success: Scalars['Boolean']['output'];
};

export type AddTwilioPhoneNumberInput = {
  workspaceId: Scalars['ID']['input'];
};

export type Allocation = {
  __typename?: 'Allocation';
  created: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  isVisible: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  totalAmount: Scalars['Int']['output'];
};

export enum AmountType {
  Gross = 'gross',
  Net = 'net'
}

export type AnalyticsFilterDateInput = {
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type AnalyticsFilterInput = {
  amountType: AmountType;
  campaignId?: InputMaybe<Scalars['ID']['input']>;
  date?: InputMaybe<AnalyticsFilterDateInput>;
};

export type AnalyticsSources = {
  __typename?: 'AnalyticsSources';
  email: Scalars['Int']['output'];
  offline: Scalars['Int']['output'];
  other: Scalars['Int']['output'];
  sms: Scalars['Int']['output'];
  social: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  website: Scalars['Int']['output'];
};

export type AnyOrder = OfflineOrder | OnlineOrder;

export type AnyTransaction = BankAccountTransaction | CardTransaction | ChariotTransaction | OfflineTransaction;

export type ArchiveCampaignInput = {
  id: Scalars['ID']['input'];
};

export type ArchiveImageInput = {
  id: Scalars['ID']['input'];
};

export type ArchivePageInput = {
  id: Scalars['ID']['input'];
};

export type AuthResult = {
  __typename?: 'AuthResult';
  accessToken: Scalars['String']['output'];
};

export type Automation = EmailAutomation | SmsAutomation;

export type AutomationList = {
  __typename?: 'AutomationList';
  dedicationEmail: Automation;
  failedRecurringCharge: Automation;
  makeYourGiftGoFurther: Automation;
  recurringDonationCanceled: Automation;
  replyToDonor: Automation;
  resubscribeToRecurring: Automation;
  thankYouNote: Automation;
  welcomeNewDonorEmail: Automation;
  welcomeNewDonorSms: Automation;
};

export type AutomationSettings = {
  __typename?: 'AutomationSettings';
  logoWidthPct: Scalars['Int']['output'];
};

export enum AutomationType {
  DedicationEmail = 'dedicationEmail',
  FailedRecurringCharge = 'failedRecurringCharge',
  MakeYourGiftGoFurther = 'makeYourGiftGoFurther',
  RecurringDonationCanceled = 'recurringDonationCanceled',
  ReplyToDonor = 'replyToDonor',
  ResubscribeToRecurring = 'resubscribeToRecurring',
  ThankYouNote = 'thankYouNote',
  WelcomeNewDonorEmail = 'welcomeNewDonorEmail',
  WelcomeNewDonorSms = 'welcomeNewDonorSms'
}

export type BankAccountTransaction = Transaction & {
  __typename?: 'BankAccountTransaction';
  amount: Scalars['Int']['output'];
  created: Scalars['Date']['output'];
  createdTzString: Scalars['String']['output'];
  detail: PaymentDetailBankAccount;
  donation: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  intent: TransactionIntent;
  net: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  order: OnlineOrder;
  processingFee: Scalars['Int']['output'];
  receiptSent?: Maybe<Scalars['Date']['output']>;
  refunded: Scalars['Boolean']['output'];
  status?: Maybe<TransactionStatus>;
  tip: Scalars['Int']['output'];
  type: TransactionType;
};

export type BeginConstantContactImportInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type BeginConstantContactImportResult = {
  __typename?: 'BeginConstantContactImportResult';
  constantContactImportId: Scalars['ID']['output'];
  lists: Array<ConstantContactList>;
};

export type BeginConstantContactIntegrationConnectInput = {
  workspaceId: Scalars['ID']['input'];
};

export type BeginConstantContactIntegrationConnectResult = {
  __typename?: 'BeginConstantContactIntegrationConnectResult';
  redirectUrl: Scalars['String']['output'];
};

export type BeginCsvImportInput = {
  columns: Array<CsvImportColumn>;
  tagId?: InputMaybe<Scalars['ID']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type BeginCsvImportResult = {
  __typename?: 'BeginCsvImportResult';
  csvImportId: Scalars['ID']['output'];
  signedUrl: Scalars['String']['output'];
};

export type BeginFacebookConnectInput = {
  accessToken: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type BeginFacebookConnectResult = {
  __typename?: 'BeginFacebookConnectResult';
  pages: Array<FacebookPage>;
};

export type BeginImageUploadInput = {
  height: Scalars['Int']['input'];
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  imageType: ImageType;
  width: Scalars['Int']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type BeginImageUploadResult = {
  __typename?: 'BeginImageUploadResult';
  imageUploadId: Scalars['ID']['output'];
  signedUrl: Scalars['String']['output'];
};

export type BeginSalesforceConnectInput = {
  workspaceId: Scalars['ID']['input'];
};

export type BeginSalesforceConnectResult = {
  __typename?: 'BeginSalesforceConnectResult';
  redirectUrl: Scalars['String']['output'];
};

export type BeginStripeConnectInput = {
  accountType: StripeAccountType;
  redirectUri: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type BeginStripeConnectResult = {
  __typename?: 'BeginStripeConnectResult';
  oAuthLink: Scalars['String']['output'];
};

export type BillingAddress = {
  __typename?: 'BillingAddress';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type BillingAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type BillingPlan = {
  __typename?: 'BillingPlan';
  name: BillingPlanName;
  periods: BillingPlanPeriods;
};

export enum BillingPlanName {
  Engage = 'engage',
  Enterprise = 'enterprise',
  Free = 'free',
  Integrate = 'integrate'
}

export type BillingPlanPeriod = {
  __typename?: 'BillingPlanPeriod';
  id: Scalars['ID']['output'];
  period: BillingPlanPeriodType;
  price: Scalars['Int']['output'];
};

export enum BillingPlanPeriodType {
  Monthly = 'Monthly',
  Yearly = 'Yearly'
}

export type BillingPlanPeriods = {
  __typename?: 'BillingPlanPeriods';
  monthly: BillingPlanPeriod;
  yearly?: Maybe<BillingPlanPeriod>;
};

export type BillingPlans = {
  __typename?: 'BillingPlans';
  engage: BillingPlan;
  enterprise: BillingPlan;
  integrate: BillingPlan;
};

export type BooleanSegmentValue = {
  __typename?: 'BooleanSegmentValue';
  operator: SegmentConditionOperator;
  type: SegmentConditionType;
  value: Scalars['Boolean']['output'];
};

export type Campaign = {
  __typename?: 'Campaign';
  content: CampaignPost;
  created: Scalars['Date']['output'];
  date: CampaignDate;
  dateFilter: CampaignDateFilter;
  displayDateTime?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  page: Page;
  status: CampaignStatus;
  time?: Maybe<CampaignTime>;
  title: Scalars['String']['output'];
  type: CampaignType;
};

export type CampaignDate = {
  __typename?: 'CampaignDate';
  day: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export enum CampaignDateFilter {
  Past = 'Past',
  Upcoming = 'Upcoming'
}

export type CampaignDateInput = {
  day: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type CampaignPost = FacebookPost | Sms;

export enum CampaignStatus {
  Archived = 'archived',
  Draft = 'draft',
  Scheduled = 'scheduled',
  Sending = 'sending',
  Sent = 'sent'
}

export type CampaignTime = {
  __typename?: 'CampaignTime';
  hour: Scalars['Int']['output'];
  minute: Scalars['Int']['output'];
};

export type CampaignTimeInput = {
  hour: Scalars['Int']['input'];
  minute: Scalars['Int']['input'];
};

export enum CampaignType {
  Facebook = 'facebook',
  Sms = 'sms'
}

export type CancelInvitationInput = {
  id: Scalars['ID']['input'];
};

export type CancelInvitationResult = {
  __typename?: 'CancelInvitationResult';
  id: Scalars['ID']['output'];
};

export type CancelProSubscriptionInput = {
  workspaceId: Scalars['ID']['input'];
};

export type CancelRecurringDonationInput = {
  orderId: Scalars['ID']['input'];
};

export type CardTransaction = Transaction & {
  __typename?: 'CardTransaction';
  amount: Scalars['Int']['output'];
  created: Scalars['Date']['output'];
  createdTzString: Scalars['String']['output'];
  detail: PaymentDetailCard;
  donation: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  intent: TransactionIntent;
  net: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  order: OnlineOrder;
  processingFee: Scalars['Int']['output'];
  receiptSent?: Maybe<Scalars['Date']['output']>;
  refunded: Scalars['Boolean']['output'];
  status?: Maybe<TransactionStatus>;
  tip: Scalars['Int']['output'];
  type: TransactionType;
};

export enum ChariotError {
  None = 'None',
  Overcharged = 'Overcharged',
  Unknown = 'Unknown',
  WorkflowSessionExpired = 'WorkflowSessionExpired'
}

export type ChariotTransaction = Transaction & {
  __typename?: 'ChariotTransaction';
  amount: Scalars['Int']['output'];
  created: Scalars['Date']['output'];
  createdTzString: Scalars['String']['output'];
  detail: PaymentDetailChariot;
  donation: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  intent: TransactionIntent;
  net: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  order: OnlineOrder;
  processingFee: Scalars['Int']['output'];
  receiptSent?: Maybe<Scalars['Date']['output']>;
  refunded: Scalars['Boolean']['output'];
  status?: Maybe<TransactionStatus>;
  tip: Scalars['Int']['output'];
  type: TransactionType;
};

export type ChurnReport = {
  __typename?: 'ChurnReport';
  customers: Array<ChurnReportCustomer>;
  pageInfo?: Maybe<PageInfo>;
};

export type ChurnReportCustomer = {
  __typename?: 'ChurnReportCustomer';
  billingCanceled?: Maybe<Scalars['Date']['output']>;
  billingChurned?: Maybe<Scalars['Date']['output']>;
  billingStarted?: Maybe<Scalars['Date']['output']>;
  nonprofitName: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
  workspaceHubSpotConsultant?: Maybe<Scalars['String']['output']>;
  workspaceHubSpotOwnerId?: Maybe<Scalars['Int']['output']>;
  workspaceId: Scalars['ID']['output'];
};

export type ClonePageInput = {
  pageId: Scalars['ID']['input'];
};

export type ClosePageInput = {
  id: Scalars['ID']['input'];
};

export type CommunityList = {
  __typename?: 'CommunityList';
  pageInfo: CommunityListPageInfo;
  posts: Array<Post>;
};

export type CommunityListPageInfo = {
  __typename?: 'CommunityListPageInfo';
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type CommunityListPageInput = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type CompleteMailchimpImportInput = {
  columns: Array<MailchimpImportColumnInput>;
  mailchimpImportId: Scalars['ID']['input'];
};

export type CompleteMailchimpIntegrationInput = {
  code: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type ConnectDoubleTheDonationIntegrationInput = {
  privateKey: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type ConstantContactList = {
  __typename?: 'ConstantContactList';
  description?: Maybe<Scalars['String']['output']>;
  favorite: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  memberCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ConstantContactListsInput = {
  workspaceId: Scalars['ID']['input'];
};

export type ConstantContactListsResult = {
  __typename?: 'ConstantContactListsResult';
  lists: Array<ConstantContactList>;
};

export type ConstantContactSample = {
  __typename?: 'ConstantContactSample';
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type Contact = {
  __typename?: 'Contact';
  address: ContactAddress;
  created: Scalars['Date']['output'];
  createdTzString: Scalars['Date']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  institutions: Array<Institution>;
  lastName: Scalars['String']['output'];
  modified: Scalars['Date']['output'];
  modifiedTzString: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  softCreditTransactions: Array<AnyTransaction>;
  subscriptions: ContactSubscriptions;
  tags: Array<Tag>;
  totalAmount: Scalars['Int']['output'];
  transactions: Array<AnyTransaction>;
};

export type ContactAddress = {
  __typename?: 'ContactAddress';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type ContactAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum ContactColumnName {
  AddressLine1 = 'AddressLine1',
  AddressLine2 = 'AddressLine2',
  City = 'City',
  Country = 'Country',
  Email = 'Email',
  FirstName = 'FirstName',
  LastName = 'LastName',
  Notes = 'Notes',
  Phone = 'Phone',
  State = 'State',
  Zip = 'Zip'
}

export type ContactList = {
  __typename?: 'ContactList';
  contacts: Array<Contact>;
  pageInfo: ContactListPageInfo;
};

export enum ContactListOrderBy {
  Created = 'created',
  Email = 'email',
  Modified = 'modified',
  Name = 'name',
  Phone = 'phone'
}

export enum ContactListOrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type ContactListOrderInput = {
  by: ContactListOrderBy;
  direction: ContactListOrderDirection;
};

export type ContactListPageInfo = {
  __typename?: 'ContactListPageInfo';
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type ContactListPageInput = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type ContactSubscriptions = {
  __typename?: 'ContactSubscriptions';
  email: EmailSubscriptionStatus;
  sms: SmsSubscriptionStatus;
};

export type CreateAllocationInput = {
  name: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type CreateBillingTokenInput = {
  workspaceId: Scalars['ID']['input'];
};

export type CreateBillingTokenResult = {
  __typename?: 'CreateBillingTokenResult';
  billingTokenId: Scalars['ID']['output'];
  token: Scalars['String']['output'];
};

export type CreateCampaignInput = {
  date: CampaignDateInput;
  pageId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  type: CampaignType;
};

export type CreateCheckoutSessionInput = {
  billingPeriod: BillingPlanPeriodType;
  billingPlan: BillingPlanName;
  cancelUrl: Scalars['String']['input'];
  successUrl: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type CreateCheckoutSessionResult = {
  __typename?: 'CreateCheckoutSessionResult';
  sessionId: Scalars['String']['output'];
};

export type CreateContactInput = {
  address?: InputMaybe<ContactAddressInput>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  institutionId?: InputMaybe<Scalars['ID']['input']>;
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type CreateCustomDomainInput = {
  domain: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type CreateEmbeddableDonationFormInput = {
  data: Scalars['String']['input'];
  pageId: Scalars['ID']['input'];
  version: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type CreateEmbeddableDonationFormResult = {
  __typename?: 'CreateEmbeddableDonationFormResult';
  id: Scalars['ID']['output'];
};

export type CreateFundraiserInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  pageId: Scalars['ID']['input'];
  profilePictureUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInstitutionInput = {
  address?: InputMaybe<InstitutionAddressInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  type: InstitutionType;
  workspaceId: Scalars['ID']['input'];
};

export type CreateInvitationLinkInput = {
  id: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type CreateInvitationLinkResult = {
  __typename?: 'CreateInvitationLinkResult';
  link: Scalars['String']['output'];
};

export type CreateMailchimpAudienceInput = {
  audienceFooterContactInfo: MailchimpAudienceFooterContactInfoInput;
  audienceName: Scalars['String']['input'];
  campaignDefaults: MailchimpCampaignDefaultsInput;
  workspaceId: Scalars['ID']['input'];
};

export type CreateMailchimpCampaignInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['String']['input'];
  htmlBody?: InputMaybe<Scalars['String']['input']>;
  segmentId: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type CreateMailchimpCampaignOutput = {
  __typename?: 'CreateMailchimpCampaignOutput';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreateOfflineDonationInput = {
  allocationId?: InputMaybe<Scalars['ID']['input']>;
  amount: Scalars['Int']['input'];
  anonymous: Scalars['Boolean']['input'];
  contactId?: InputMaybe<Scalars['ID']['input']>;
  date: OfflineDonationDateInput;
  dedication?: InputMaybe<DedicationInput>;
  fundraiserId?: InputMaybe<Scalars['ID']['input']>;
  institutionId?: InputMaybe<Scalars['ID']['input']>;
  nonDeductibleAmount: Scalars['Int']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  pageId: Scalars['ID']['input'];
  paymentMethodType: OfflinePaymentMethodType;
  pledge: Scalars['Boolean']['input'];
  softCreditContactId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateOrderChariotInput = {
  address: BillingAddressInput;
  allocationId?: InputMaybe<Scalars['String']['input']>;
  amount: Scalars['Int']['input'];
  anonymous: Scalars['Boolean']['input'];
  campaignId?: InputMaybe<Scalars['ID']['input']>;
  dedication?: InputMaybe<DedicationInput>;
  email: Scalars['String']['input'];
  fundId: Scalars['String']['input'];
  fundraiserId?: InputMaybe<Scalars['ID']['input']>;
  pageId: Scalars['ID']['input'];
  recaptchaResponse: Scalars['String']['input'];
  userFriendlyId: Scalars['String']['input'];
  utm?: InputMaybe<UtmDataInput>;
  workflowSessionId: Scalars['String']['input'];
};

export type CreateOrderChariotResult = {
  __typename?: 'CreateOrderChariotResult';
  error: ChariotError;
  id?: Maybe<Scalars['ID']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreateOrderInput = {
  address: BillingAddressInput;
  allocationId?: InputMaybe<Scalars['String']['input']>;
  amount: Scalars['Int']['input'];
  anonymous: Scalars['Boolean']['input'];
  campaignId?: InputMaybe<Scalars['ID']['input']>;
  dedication?: InputMaybe<DedicationInput>;
  donorCoversProcessingFees: Scalars['Boolean']['input'];
  doubleTheDonationCompanyId?: InputMaybe<Scalars['String']['input']>;
  doubleTheDonationEnteredText?: InputMaybe<Scalars['String']['input']>;
  doubleTheDonationStatus?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  fundraiserId?: InputMaybe<Scalars['ID']['input']>;
  pageId: Scalars['ID']['input'];
  recaptchaResponse: Scalars['String']['input'];
  recurrenceInterval: DonationRecurrenceInterval;
  stripePaymentMethod: StripePaymentMethodType;
  tip: Scalars['Int']['input'];
  utm?: InputMaybe<UtmDataInput>;
};

export type CreateOrderResult = {
  __typename?: 'CreateOrderResult';
  clientSecret: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type CreatePageInput = {
  title: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type CreateSegmentInput = {
  conditions: Scalars['String']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type CreateTagInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type CreateWorkspaceInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  contact: WorkspaceContactInput;
  howDidYouHearAboutUs?: InputMaybe<Scalars['String']['input']>;
  nonprofitName: Scalars['String']['input'];
  nonprofitTaxId: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
  workspaceName?: InputMaybe<Scalars['String']['input']>;
};

export type CsvImportColumn = {
  column: ContactColumnName;
  index: Scalars['Int']['input'];
};

export type CsvResult = {
  __typename?: 'CsvResult';
  csv: Scalars['String']['output'];
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isMasterAdmin: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
  roles: Array<WorkspaceRole>;
  state: UserState;
};

export type CurrentUserOrder = {
  __typename?: 'CurrentUserOrder';
  amount: Scalars['Int']['output'];
  campaignTitle?: Maybe<Scalars['String']['output']>;
  canceled?: Maybe<Scalars['Date']['output']>;
  created: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  nextPayment?: Maybe<Scalars['Date']['output']>;
  page: Page;
  paymentFailing: Scalars['Boolean']['output'];
  paymentMethodType: CurrentUserPaymentMethodType;
  receipts: Array<Receipt>;
  recurrenceInterval: DonationRecurrenceInterval;
  recurring: Scalars['Boolean']['output'];
  utm: UtmData;
};

export enum CurrentUserPaymentMethodType {
  BankAccount = 'bank_account',
  Card = 'card',
  Cash = 'cash',
  DonorAdvisedFund = 'donor_advised_fund',
  Eft = 'eft',
  InKind = 'in_kind',
  Other = 'other',
  PhysicalCheck = 'physical_check',
  Stock = 'stock'
}

export type CustomDomain = {
  __typename?: 'CustomDomain';
  created: Scalars['Date']['output'];
  domain: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isValid: Scalars['Boolean']['output'];
  subdomain: Scalars['String']['output'];
};

export type CustomerTransactionReportResult = {
  __typename?: 'CustomerTransactionReportResult';
  success: Scalars['Boolean']['output'];
};

export type DateSegmentValue = {
  __typename?: 'DateSegmentValue';
  operator: SegmentConditionOperator;
  type: SegmentConditionType;
  value: SegmentDate;
};

export enum DayOfWeek {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export type Dedication = {
  __typename?: 'Dedication';
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  type: DedicationType;
};

export type DedicationInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  notification?: InputMaybe<DedicationNotificationInput>;
  type: DedicationType;
};

export type DedicationNotificationInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
};

export enum DedicationType {
  InHonor = 'in_honor',
  InMemory = 'in_memory'
}

export type DeleteAllocationInput = {
  id: Scalars['ID']['input'];
};

export type DeleteAllocationResult = {
  __typename?: 'DeleteAllocationResult';
  success: Scalars['Boolean']['output'];
};

export type DeleteContactFromInstitutionInput = {
  contactId: Scalars['ID']['input'];
  institutionId: Scalars['ID']['input'];
};

export type DeleteContactFromInstitutionResult = {
  __typename?: 'DeleteContactFromInstitutionResult';
  contact: Contact;
  institution: Institution;
};

export type DeleteContactInput = {
  id: Scalars['ID']['input'];
};

export type DeleteContactResult = {
  __typename?: 'DeleteContactResult';
  success: Scalars['Boolean']['output'];
};

export type DeleteCustomDomainInput = {
  id: Scalars['ID']['input'];
};

export type DeleteCustomDomainResult = {
  __typename?: 'DeleteCustomDomainResult';
  success: Scalars['Boolean']['output'];
};

export type DeleteEmbeddableDonationFormInput = {
  id: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type DeleteEmbeddableDonationFormResult = {
  __typename?: 'DeleteEmbeddableDonationFormResult';
  success: Scalars['Boolean']['output'];
};

export type DeleteFundraiserInput = {
  id: Scalars['ID']['input'];
};

export type DeleteFundraiserResult = {
  __typename?: 'DeleteFundraiserResult';
  success: Scalars['Boolean']['output'];
};

export type DeleteInstitutionInput = {
  id: Scalars['ID']['input'];
};

export type DeleteInstitutionResult = {
  __typename?: 'DeleteInstitutionResult';
  success: Scalars['Boolean']['output'];
};

export type DeleteOfflineDonationInput = {
  id: Scalars['ID']['input'];
};

export type DeleteOfflineDonationResult = {
  __typename?: 'DeleteOfflineDonationResult';
  success: Scalars['Boolean']['output'];
};

export type DeleteSegmentInput = {
  id: Scalars['ID']['input'];
};

export type DeleteSegmentResult = {
  __typename?: 'DeleteSegmentResult';
  success: Scalars['Boolean']['output'];
};

export type DeleteTagInput = {
  id: Scalars['ID']['input'];
};

export type DeleteTagResult = {
  __typename?: 'DeleteTagResult';
  success: Scalars['Boolean']['output'];
};

export type DisableMailchimpIntegrationInput = {
  workspaceId: Scalars['ID']['input'];
};

export type DisconnectConstantContactIntegrationInput = {
  workspaceId: Scalars['ID']['input'];
};

export type DisconnectDoubleTheDonationIntegrationInput = {
  workspaceId: Scalars['ID']['input'];
};

export type DisconnectFacebookInput = {
  workspaceId: Scalars['ID']['input'];
};

export type DisconnectSalesforceInput = {
  workspaceId: Scalars['ID']['input'];
};

export type DisconnectTextToDonateInput = {
  workspaceId: Scalars['ID']['input'];
};

export type DonateButton = {
  __typename?: 'DonateButton';
  appId: Scalars['String']['output'];
  color: Scalars['String']['output'];
  page: Page;
};

export enum DonationRecurrenceInterval {
  Monthly = 'monthly',
  None = 'none',
  Quarterly = 'quarterly',
  TwiceAYear = 'twiceAYear',
  Yearly = 'yearly'
}

export type DonationTier = {
  __typename?: 'DonationTier';
  amount: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  recurrenceInterval: DonationRecurrenceInterval;
};

export type DonationTierInput = {
  amount: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  isDefault: Scalars['Boolean']['input'];
  recurrenceInterval: DonationRecurrenceInterval;
};

export enum DonationTiersOrder {
  Ascending = 'ascending',
  Descending = 'descending'
}

export type DonorPortalBeginUpdateOrderInput = {
  orderId: Scalars['ID']['input'];
  recurrenceInterval: DonationRecurrenceInterval;
};

export type DonorPortalBeginUpdateOrderResult = {
  __typename?: 'DonorPortalBeginUpdateOrderResult';
  clientSecret: Scalars['String']['output'];
};

export type DonorPortalCancelRecurringDonationInput = {
  orderId: Scalars['ID']['input'];
};

export type DonorPortalEndUpdateOrderInput = {
  orderId: Scalars['ID']['input'];
  paymentMethodId: Scalars['String']['input'];
  recurrenceInterval: DonationRecurrenceInterval;
};

export type DonorPortalRestartRecurringDonationInput = {
  orderId: Scalars['ID']['input'];
};

export type DonorPortalUpdateOrderAchInput = {
  orderId: Scalars['ID']['input'];
  plaidAccountId?: InputMaybe<Scalars['String']['input']>;
  plaidToken?: InputMaybe<Scalars['String']['input']>;
  recurrenceInterval: DonationRecurrenceInterval;
};

export type DonorSignInWithTokenInput = {
  token: Scalars['String']['input'];
  tokenId: Scalars['ID']['input'];
};

export type DonorVerifyEmailInput = {
  email: Scalars['String']['input'];
  recaptchaResponse: Scalars['String']['input'];
};

export type DonorVerifyEmailResult = {
  __typename?: 'DonorVerifyEmailResult';
  donorSignInEmailSent: Scalars['Boolean']['output'];
};

export type DownloadStockImageInput = {
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  stockImageId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type EmailAssistantResponse = {
  __typename?: 'EmailAssistantResponse';
  chunk: Scalars['String']['output'];
};

export type EmailAutomation = {
  __typename?: 'EmailAutomation';
  data: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  showUnsubscribe: Scalars['Boolean']['output'];
  subject: Scalars['String']['output'];
  type: AutomationType;
};

export type EmailCampaign = {
  __typename?: 'EmailCampaign';
  createdAt: Scalars['Date']['output'];
  emailTemplates?: Maybe<Array<EmailTemplate>>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  openaiThreadId: Scalars['String']['output'];
  threadMessages?: Maybe<Array<ThreadMessage>>;
  updatedAt: Scalars['Date']['output'];
  webId: Scalars['ID']['output'];
};

export type EmailDetail = {
  __typename?: 'EmailDetail';
  body: Scalars['String']['output'];
  emailId: Scalars['String']['output'];
  subject: Scalars['String']['output'];
};

export type EmailFunnel = {
  __typename?: 'EmailFunnel';
  clicked: Scalars['Int']['output'];
  delivered: Scalars['Int']['output'];
  donated: Scalars['Int']['output'];
  opened: Scalars['Int']['output'];
  sent: Scalars['Int']['output'];
};

export type EmailPopup = {
  __typename?: 'EmailPopup';
  appId: Scalars['String']['output'];
  color: Scalars['String']['output'];
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum EmailSubscriptionStatus {
  Subscribed = 'subscribed',
  Unsubscribed = 'unsubscribed'
}

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  body?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  emailCampaign?: Maybe<EmailCampaign>;
  emailCampaignId: Scalars['Int']['output'];
  subject?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  webId: Scalars['ID']['output'];
};

export type EmbeddableDonationFormListRecord = {
  __typename?: 'EmbeddableDonationFormListRecord';
  id: Scalars['ID']['output'];
  page: EmbeddableDonationFormPage;
};

export type EmbeddableDonationFormPage = {
  __typename?: 'EmbeddableDonationFormPage';
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type EmbeddableForm = {
  __typename?: 'EmbeddableForm';
  created: Scalars['Date']['output'];
  data: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  pageId: Scalars['ID']['output'];
  version: Scalars['String']['output'];
  workspaceId: Scalars['ID']['output'];
};

export type EndConstantContactImportInput = {
  constantContactImportId: Scalars['ID']['input'];
};

export type EndConstantContactImportResult = {
  __typename?: 'EndConstantContactImportResult';
  constantContactImportId: Scalars['ID']['output'];
};

export type EndConstantContactIntegrationConnectInput = {
  code: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type EndCsvImportInput = {
  csvImportId: Scalars['ID']['input'];
};

export type EndCsvImportResult = {
  __typename?: 'EndCsvImportResult';
  csvImportId: Scalars['ID']['output'];
};

export type EndFacebookConnectInput = {
  facebookPageId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type EndImageUploadInput = {
  imageUploadId: Scalars['ID']['input'];
};

export type EndSalesforceConnectInput = {
  code: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type EndStripeConnectInput = {
  code: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type ExportContactsFinanceResult = {
  __typename?: 'ExportContactsFinanceResult';
  success: Scalars['Boolean']['output'];
};

export type ExportContactsInput = {
  segment?: InputMaybe<SegmentConditionsInput>;
  workspaceId: Scalars['ID']['input'];
};

export type ExportContactsResult = {
  __typename?: 'ExportContactsResult';
  downloadUrl: Scalars['String']['output'];
};

export type ExportDownloadInput = {
  exportId: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type ExportDownloadResult = {
  __typename?: 'ExportDownloadResult';
  fileName: Scalars['String']['output'];
  link: Scalars['String']['output'];
};

export type ExportInstitutionsInput = {
  workspaceId: Scalars['ID']['input'];
};

export type ExportTransactionsInput = {
  filter?: InputMaybe<TransactionListFilterInput>;
  order?: InputMaybe<TransactionListOrderInput>;
  payoutId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type ExportTransactionsResult = {
  __typename?: 'ExportTransactionsResult';
  downloadUrl: Scalars['String']['output'];
};

export type FacebookPage = {
  __typename?: 'FacebookPage';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  profilePictureUrl: Scalars['String']['output'];
};

export type FacebookPost = {
  __typename?: 'FacebookPost';
  text: Scalars['String']['output'];
};

export type Fundraiser = {
  __typename?: 'Fundraiser';
  created: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
  raised: Scalars['Int']['output'];
};

export type GetEmbeddableDonationFormDetailByPageWebIdInput = {
  pageWebId: Scalars['String']['input'];
};

export type GetEmbeddableDonationFormDetailByPageWebIdResult = {
  __typename?: 'GetEmbeddableDonationFormDetailByPageWebIdResult';
  data?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<EmbeddableDonationFormPage>;
};

export type GetEmbeddableDonationFormDetailInput = {
  id: Scalars['String']['input'];
};

export type GetEmbeddableDonationFormDetailResult = {
  __typename?: 'GetEmbeddableDonationFormDetailResult';
  data: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  page: EmbeddableDonationFormPage;
};

export type Goal = {
  __typename?: 'Goal';
  amount: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  startDate?: Maybe<Scalars['Date']['output']>;
};

export type GoalInput = {
  amount: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type HideWorkspaceInput = {
  workspaceId: Scalars['ID']['input'];
};

export type Image = {
  __typename?: 'Image';
  filePath: Scalars['String']['output'];
  height: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  width: Scalars['Int']['output'];
};

export enum ImageType {
  Gif = 'GIF',
  Jpeg = 'JPEG',
  Png = 'PNG',
  Svg = 'SVG',
  Tiff = 'TIFF',
  Webp = 'WEBP'
}

export type InitiateMailchimpImportInput = {
  mailchimpApiKey: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type InitiateMailchimpImportOutput = {
  __typename?: 'InitiateMailchimpImportOutput';
  audiences: Array<MailchimpAudience>;
  mailchimpImportId: Scalars['ID']['output'];
};

export type InitiateMailchimpIntegrationInput = {
  workspaceId: Scalars['ID']['input'];
};

export type InitiateMailchimpIntegrationOutput = {
  __typename?: 'InitiateMailchimpIntegrationOutput';
  redirectUrl: Scalars['String']['output'];
};

export type Institution = {
  __typename?: 'Institution';
  address?: Maybe<InstitutionAddress>;
  contactCount: Scalars['Int']['output'];
  contacts: Array<Contact>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modified: Scalars['Date']['output'];
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  totalAmount: Scalars['Int']['output'];
  transactions: Array<AnyTransaction>;
  type: InstitutionType;
};

export type InstitutionAddress = {
  __typename?: 'InstitutionAddress';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type InstitutionAddressInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type InstitutionList = {
  __typename?: 'InstitutionList';
  institutions: Array<Institution>;
  pageInfo: InstitutionListPageInfo;
};

export enum InstitutionListOrderBy {
  Contacts = 'contacts',
  Modified = 'modified',
  Name = 'name',
  TotalAmount = 'totalAmount',
  Type = 'type'
}

export enum InstitutionListOrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type InstitutionListOrderInput = {
  by: InstitutionListOrderBy;
  direction: InstitutionListOrderDirection;
};

export type InstitutionListPageInfo = {
  __typename?: 'InstitutionListPageInfo';
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type InstitutionListPageInput = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export enum InstitutionType {
  Corporation = 'corporation',
  DonorAdvisedFund = 'donorAdvisedFund',
  Foundation = 'foundation',
  Government = 'government',
  Household = 'household',
  Other = 'other',
  PlaceOfWorship = 'placeOfWorship',
  School = 'school'
}

export type InstitutionsAndContactsResult = {
  __typename?: 'InstitutionsAndContactsResult';
  searchApiKey: Scalars['String']['output'];
};

export type IntSegmentValue = {
  __typename?: 'IntSegmentValue';
  operator: SegmentConditionOperator;
  type: SegmentConditionType;
  value: Scalars['Int']['output'];
};

export type Invitation = {
  __typename?: 'Invitation';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  role: Role;
};

export type InvitationInput = {
  id: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type InviteWorkspaceMemberInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  role: Role;
  workspaceId: Scalars['ID']['input'];
};

export type InvitedBy = {
  __typename?: 'InvitedBy';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type LegacyDemo = {
  __typename?: 'LegacyDemo';
  appId: Scalars['ID']['output'];
  slug: Scalars['ID']['output'];
  workspaceId: Scalars['ID']['output'];
};

export type ListEmbeddableDonationFormsInput = {
  workspaceId: Scalars['ID']['input'];
};

export type ListEmbeddableDonationFormsResult = {
  __typename?: 'ListEmbeddableDonationFormsResult';
  forms: Array<EmbeddableDonationFormListRecord>;
};

export type ListInstitutionsInput = {
  workspaceId: Scalars['ID']['input'];
};

export type ListMailchimpAudiencesInput = {
  workspaceId: Scalars['ID']['input'];
};

export type ListMailchimpAudiencesOutput = {
  __typename?: 'ListMailchimpAudiencesOutput';
  audiences: Array<MailchimpAudience>;
};

export type MailchimpAudience = {
  __typename?: 'MailchimpAudience';
  id: Scalars['ID']['output'];
  memberCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type MailchimpAudienceFooterContactInfoInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  company: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type MailchimpCampaignDefaultsInput = {
  fromEmail: Scalars['String']['input'];
  fromName: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type MailchimpImportColumnInput = {
  column: ContactColumnName;
  index: Scalars['Int']['input'];
};

export type ManageBillingInput = {
  redirectUri: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type ManageBillingResult = {
  __typename?: 'ManageBillingResult';
  sessionUrl: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * ## Errors
   *
   * #### invalid_invitation_token
   * Invitation token is invalid or expired
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  acceptInvitationToken: AcceptInvitationTokenResult;
  /**
   * ## Errors
   *
   * #### institution_already_connected
   * Institution already connected
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  addContactToInstitution: AddContactToInstitutionResult;
  addOrderComment: AddOrderCommentResult;
  /**
   * ## Errors
   *
   * #### user_input_error
   * Maximum phone count reached.
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  addTwilioPhoneNumber: TwilioPhoneNumber;
  archiveCampaign: Campaign;
  archiveImage: Image;
  archivePage: Page;
  /**
   * ## Errors
   *
   * #### constant_contact_auth
   * Constant Contact auth code is invalid
   * #### no_constant_contact_contacts
   * This Constant Contact account has no contacts in contact lists
   */
  beginConstantContactImport: BeginConstantContactImportResult;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  beginConstantContactIntegrationConnect: BeginConstantContactIntegrationConnectResult;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  beginCsvImport: BeginCsvImportResult;
  /**
   * ## Errors
   *
   * #### no_facebook_pages
   * No Facebook pages listed for selected account
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  beginFacebookConnect: BeginFacebookConnectResult;
  beginImageUpload: BeginImageUploadResult;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  beginSalesforceConnect: BeginSalesforceConnectResult;
  beginStripeConnect: BeginStripeConnectResult;
  cancelInvitation: CancelInvitationResult;
  cancelProSubscription: Workspace;
  cancelRecurringDonation: OnlineOrder;
  /**
   * ## Errors
   *
   * #### not_found
   * Page not found
   */
  clonePage: Page;
  /**
   * ## Errors
   *
   * #### page_already_has_scheduled_campaigns
   * Cannot unpublish page which already has scheduled campaigns
   */
  closePage: Page;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  completeMailchimpIntegration: Workspace;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   *
   * #### invalid_double_the_donation_keys
   * Invalid double the donation keys
   */
  connectDoubleTheDonationIntegration: Workspace;
  /**
   * ## Errors
   *
   * #### allocation_duplicate_name
   * Allocation with specified name already exists in your workspace
   */
  createAllocation: Allocation;
  createBillingToken: CreateBillingTokenResult;
  createCampaign: Campaign;
  /**
   * ## Errors
   *
   * #### not_found
   * Workspace not found
   */
  createCheckoutSession: CreateCheckoutSessionResult;
  /**
   * ## Errors
   *
   * #### email_already_exists
   * Contact with specified email already exists in your workspace
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  createContact: Contact;
  /**
   * ## Errors
   *
   * #### custom_domain_already_exists
   * Custom domain already exists
   *
   * #### invalid_domain
   * Invalid domain
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  createCustomDomain: CustomDomain;
  createDemoWorkspace: Workspace;
  createEmailCampaign: EmailCampaign;
  createEmailTemplate: EmailTemplate;
  createEmbeddableDonationForm: CreateEmbeddableDonationFormResult;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  createFundraiser: Fundraiser;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  createInstitution: Institution;
  createInvitationLink: CreateInvitationLinkResult;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  createMailchimpAudience: Workspace;
  createMailchimpCampaign?: Maybe<CreateMailchimpCampaignOutput>;
  /**
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  createOfflineDonation: OfflineTransaction;
  createOrder: CreateOrderResult;
  createOrderChariot: CreateOrderChariotResult;
  createPage: Page;
  /**
   * ## Errors
   *
   * #### segment_already_exists
   * Segment with same name already exists
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  createSegment: Segment;
  /**
   * ## Errors
   *
   * #### tag_already_exists
   * Tag with same name already exists
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  createTag: Tag;
  /**
   * ## Errors
   *
   * #### workspace_same_ein_exist
   * Workspace with the same ein already exist
   */
  createWorkspace: Workspace;
  /**
   * ## Errors
   *
   * #### allocation_has_existing_orders
   * Allocation already has orders
   */
  deleteAllocation: DeleteAllocationResult;
  /**
   * ## Errors
   *
   * #### contact_has_existing_orders
   * Contact already has orders.
   */
  deleteContact: DeleteContactResult;
  deleteContactFromInstitution: DeleteContactFromInstitutionResult;
  deleteCustomDomain: DeleteCustomDomainResult;
  deleteEmailCampaign: EmailCampaign;
  deleteEmailTemplate: Scalars['Boolean']['output'];
  deleteEmbeddableDonationForm: DeleteEmbeddableDonationFormResult;
  deleteFundraiser: DeleteFundraiserResult;
  /**
   * ## Errors
   *
   * #### institution_existing_orders
   * You cannot delete institution with orders
   */
  deleteInstitution?: Maybe<DeleteInstitutionResult>;
  deleteOfflineDonation: DeleteOfflineDonationResult;
  /**
   * ## Errors
   *
   * #### cannot_delete_segment_scheduled_campaign
   * Cannot delete segment with scheduled campaign
   */
  deleteSegment: DeleteSegmentResult;
  /**
   * ## Errors
   *
   * #### tag_used_segment
   * Tag is used in segment
   */
  deleteTag: DeleteTagResult;
  disableMailchimpIntegration: Workspace;
  disconnectConstantContactIntegration: Workspace;
  disconnectDoubleTheDonationIntegration: Workspace;
  /**
   * ## Errors
   *
   * #### facebook_post_already_scheduled
   * Cannot disconnect Facebook page because the workspace already has scheduled campaigns with Facebook posts.
   */
  disconnectFacebook: Workspace;
  disconnectSalesforce: Workspace;
  disconnectTextToDonate: Workspace;
  donorPortalBeginUpdateOrder: DonorPortalBeginUpdateOrderResult;
  donorPortalCancelRecurringDonation: CurrentUserOrder;
  donorPortalEndUpdateOrder: CurrentUserOrder;
  donorPortalRestartRecurringDonation: CurrentUserOrder;
  donorPortalUpdateOrderACH: CurrentUserOrder;
  /**
   * ## Errors
   *
   * #### invalid_donor_sign_in_token
   * Invalid Donor sign in token
   */
  donorSignInWithToken: AuthResult;
  /**
   * ## Errors
   *
   * #### invalid_email_password
   * Invalid email or password
   */
  donorVerifyEmail: DonorVerifyEmailResult;
  downloadStockImage: Image;
  duplicateEmailCampaign: EmailCampaign;
  endConstantContactImport: EndConstantContactImportResult;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  endConstantContactIntegrationConnect: Workspace;
  endCsvImport: EndCsvImportResult;
  endFacebookConnect: Workspace;
  endImageUpload: Image;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  endSalesforceConnect: Workspace;
  endStripeConnect: Workspace;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  exportContacts: ExportContactsResult;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  exportInstitutions: CsvResult;
  exportTransactions: ExportTransactionsResult;
  /**
   * ## Errors
   *
   * #### confirmed_orders_exist
   * Cannot hide workspace with confirmed orders
   */
  hideWorkspace: Workspace;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  initiateMailchimpIntegration: InitiateMailchimpIntegrationOutput;
  /**
   * ## Errors
   *
   * #### email_already_member
   * User with specified email is already a member of this workspace
   * #### email_already_invited
   * User with specified email has already been invited to this workspace
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  inviteWorkspaceMember: Invitation;
  manageBilling: ManageBillingResult;
  openStripe: OpenStripeResult;
  /**
   * ## Errors
   *
   * #### missing_stripe_account
   * Cannot publish a page because workspace does not have a Stripe account connected
   */
  publishPage: Page;
  readAllReplies: UnreadReplyCount;
  /**
   * ## Errors
   *
   * #### stripe_refund_error
   * Transaction cannot be refunded
   */
  refundTransaction: OnlineTransaction;
  removeWorkspaceMember: RemoveWorkspaceMemberResult;
  reportRevenuePerWorkspace: TResult;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  resendInvitation: ResendInvitationResult;
  resendReceipt: Transaction;
  /**
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  resetAutomation: Automation;
  /**
   * ## Errors
   *
   * #### invalid_password_reset_token
   * Password reset token is invalid or expired
   */
  resetPassword: AuthResult;
  /**
   * ## Errors
   *
   * #### date_time_is_in_past
   * Date and time must be in future
   * #### page_not_published
   * The fundraising page must be published before scheduling the campaign
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  scheduleCampaign: Campaign;
  selectConstantContactLists: SelectConstantContactListsResult;
  sendFeedback: SendFeedbackResult;
  sendMailchimpCampaignEmail?: Maybe<SendMailchimpCampaignEmailOutput>;
  sendMessageToEmailAssistant: SendMessageToEmailAssistantResult;
  sendPasswordChangeEmail: SendPasswordChangeEmailResult;
  sendPasswordResetEmail: SendPasswordResetEmailResult;
  /**
   * ## Errors
   *
   * #### phone_number_invalid
   * Specified phone number is invalid
   * #### phone_number_not_allowed
   * Only US and Canada phone numbers are allowed for SMS campaigns
   * #### page_not_published
   * Page must be published before sending test SMS
   */
  sendTestSms: SendTestSmsResult;
  setAllocationVisibility: Allocation;
  setAllowCustomAmount: Page;
  setAnonymous: Order;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  setConstantContactList: Workspace;
  setDefaultDonationRecurrenceInterval: Page;
  setDonationTiersOrder: Page;
  /**
   * For pledges only
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  setPledgeReceived: OfflineTransaction;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  setRole: WorkspaceMember;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  setSoftCreditContact: Order;
  /**
   * ## Errors
   *
   * #### donation_code_conflict
   * Donation code already used by a different workspace
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  setupTextToDonate: TextToDonate;
  /**
   * ## Errors
   * #### invalid_email_password
   * Invalid email or password
   * #### invalid_invitation_token
   * Invitation token is invalid or expired
   */
  signInWithEmail: AuthResult;
  /**
   * ## Errors
   *
   * #### gmail_not_found
   * User with specified email does not exist
   * #### invalid_invitation_token
   * Invitation token is invalid or expired
   */
  signInWithGmail: AuthResult;
  /**
   * ## Errors
   *
   * #### email_already_exists
   * User with specified email already exists
   * #### invalid_invitation_token
   * Invitation token is invalid or expired
   */
  signUpWithEmail: AuthResult;
  /**
   * ## Errors
   *
   * #### email_already_exists
   * User with specified email already exists
   * #### invalid_invitation_token
   * Invitation token is invalid or expired
   */
  signUpWithGmail: AuthResult;
  /**
   * ## Errors
   *
   * #### tag_already_assigned_contact
   * Tag is already assigned to contact
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  tagContact: Contact;
  /**
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  testRunAutomation: TestRunAutomationResult;
  unarchiveCampaign: Campaign;
  unarchivePage: Page;
  /**
   * ## Errors
   *
   * #### page_already_has_donations
   * Cannot unpublish page which already has donations
   * #### page_already_has_scheduled_campaigns
   * Cannot unpublish page which already has scheduled campaigns
   */
  unpublishPage: Page;
  unscheduleCampaign: Campaign;
  /**
   * ## Errors
   *
   * #### invalid_ubsubscribe_token
   * Invalid unsubscribe token
   */
  unsubscribe: UserSubscriptionStatus;
  untagContact: Contact;
  /**
   * ## Errors
   *
   * #### allocation_duplicate_name
   * Allocation with specified name already exists in your workspace
   */
  updateAllocation: Allocation;
  /**
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  updateAutomation: Automation;
  updateAutomationSettings: AutomationSettings;
  /**
   * ## Errors
   *
   * #### not_found
   * Campaign not found
   *
   * #### user_input_error
   * Campaign title cannot be empty
   */
  updateCampaign: Campaign;
  /**
   * ## Errors
   *
   * #### page_not_published
   * The fundraising page must be published in order to assign to a scheduled campaign
   */
  updateCampaignPage: Campaign;
  updateChariotIntegration: Workspace;
  updateComment: ReplyTask;
  updateContact: Contact;
  updateContactNotes: Contact;
  updateCurrentUser: CurrentUser;
  /**
   * ## Errors
   * #### custom_domain_already_exists
   * Custom domain already exists
   *
   * #### invalid_domain
   * Invalid domain
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  updateCustomDomain: CustomDomain;
  updateDonateButton: DonateButton;
  /**
   * ## Errors
   *
   * #### only_onetime_donations_allowed
   * Only one time donations are allowed for this page
   * #### only_monthly_donations_allowed
   * Only monthly donations are allowed for this page
   */
  updateDonationTiers: Page;
  updateEmailCampaign: EmailCampaign;
  updateEmailPopup: EmailPopup;
  updateEmailTemplate: EmailTemplate;
  updateEmbeddableDonationForm: UpdateEmbeddableDonationFormResult;
  /**
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  updateFacebookPixelIntegration: Workspace;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  updateFundraiser: Fundraiser;
  updateGoals: Page;
  updateGoogleAdsIntegration: Workspace;
  /**
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  updateGoogleAnalyticsIntegration: Workspace;
  /**
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  updateGoogleTagManagerIntegration: Workspace;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  updateInstitution: Institution;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  updateInstitutionNotes: Institution;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  updateInvitation: Invitation;
  updateLogo: Workspace;
  updateMailchimpAudience: UpdateMailchimpAudienceOutput;
  updateOfflineDonation: OfflineTransaction;
  updatePage: Page;
  updatePageStory: Page;
  /**
   * ## Errors
   *
   * #### not_found
   * User is not a member of the workspace, workspace admin or workspace does not exist.
   */
  updatePrimaryContact: Workspace;
  updateReply: UpdateReplyTaskResult;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  updateSalesforceSettings: Workspace;
  /**
   * ## Errors
   *
   * #### segment_already_exists
   * Segment with same name already exists
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  updateSegment: Segment;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  updateSoftCredits: Order;
  /**
   * ## Errors
   *
   * #### tag_already_exists
   * Tag with same name already exists
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  updateTag: Tag;
  /**
   * ## Errors
   *
   * #### workspace_already_has_scheduled_campaigns
   * Cannot update timezone becuase workspace already has scheduled campaigns
   */
  updateTimezone: Workspace;
  updateTransactionNotes: Transaction;
  updateTransactionPage: Transaction;
  updateWorkspace: Workspace;
  upsertEmbeddableDonationForm: UpsertEmbeddableDonationFormResult;
  /**
   * ## Errors
   *
   * #### page_not_published
   * The fundraising page must be published before scheduling the campaign
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  validateCampaign: ValidateCampaignResult;
  /**
   * ## Errors
   *
   * #### missing_stripe_account
   * Cannot publish a page because workspace does not have a Stripe account connected
   */
  validatePage: ValidatePageResult;
  /**
   * ## Errors
   *
   * #### invalid_billing_token
   * Billing token is invalid or expired
   */
  verifyBillingToken: SubscriptionStatus;
  /**
   * ## Errors
   *
   * #### invalid_invitation_token
   * Invitation token is invalid or expired
   */
  verifyInvitationToken: VerifyInvitationTokenResult;
  /**
   * ## Errors
   *
   * #### invalid_password_reset_token
   * Password reset token is invalid or expired
   */
  verifyPasswordResetToken: VerifyPasswordResetTokenResult;
  verifyWorkspace: Workspace;
};


export type MutationAcceptInvitationTokenArgs = {
  input: AcceptInvitationTokenInput;
};


export type MutationAddContactToInstitutionArgs = {
  input: AddContactToInstitutionInput;
};


export type MutationAddOrderCommentArgs = {
  input: AddOrderCommentInput;
};


export type MutationAddTwilioPhoneNumberArgs = {
  input: AddTwilioPhoneNumberInput;
};


export type MutationArchiveCampaignArgs = {
  input: ArchiveCampaignInput;
};


export type MutationArchiveImageArgs = {
  input: ArchiveImageInput;
};


export type MutationArchivePageArgs = {
  input: ArchivePageInput;
};


export type MutationBeginConstantContactImportArgs = {
  input: BeginConstantContactImportInput;
};


export type MutationBeginConstantContactIntegrationConnectArgs = {
  input: BeginConstantContactIntegrationConnectInput;
};


export type MutationBeginCsvImportArgs = {
  input: BeginCsvImportInput;
};


export type MutationBeginFacebookConnectArgs = {
  input: BeginFacebookConnectInput;
};


export type MutationBeginImageUploadArgs = {
  input: BeginImageUploadInput;
};


export type MutationBeginSalesforceConnectArgs = {
  input: BeginSalesforceConnectInput;
};


export type MutationBeginStripeConnectArgs = {
  input: BeginStripeConnectInput;
};


export type MutationCancelInvitationArgs = {
  input: CancelInvitationInput;
};


export type MutationCancelProSubscriptionArgs = {
  input: CancelProSubscriptionInput;
};


export type MutationCancelRecurringDonationArgs = {
  input: CancelRecurringDonationInput;
};


export type MutationClonePageArgs = {
  input: ClonePageInput;
};


export type MutationClosePageArgs = {
  input: ClosePageInput;
};


export type MutationCompleteMailchimpIntegrationArgs = {
  input: CompleteMailchimpIntegrationInput;
};


export type MutationConnectDoubleTheDonationIntegrationArgs = {
  input: ConnectDoubleTheDonationIntegrationInput;
};


export type MutationCreateAllocationArgs = {
  input: CreateAllocationInput;
};


export type MutationCreateBillingTokenArgs = {
  input: CreateBillingTokenInput;
};


export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput;
};


export type MutationCreateCheckoutSessionArgs = {
  input: CreateCheckoutSessionInput;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationCreateCustomDomainArgs = {
  input: CreateCustomDomainInput;
};


export type MutationCreateEmailCampaignArgs = {
  workspaceWebId: Scalars['ID']['input'];
};


export type MutationCreateEmailTemplateArgs = {
  emailCampaignWebId: Scalars['ID']['input'];
  workspaceWebId: Scalars['ID']['input'];
};


export type MutationCreateEmbeddableDonationFormArgs = {
  input: CreateEmbeddableDonationFormInput;
};


export type MutationCreateFundraiserArgs = {
  input: CreateFundraiserInput;
};


export type MutationCreateInstitutionArgs = {
  input: CreateInstitutionInput;
};


export type MutationCreateInvitationLinkArgs = {
  input: CreateInvitationLinkInput;
};


export type MutationCreateMailchimpAudienceArgs = {
  input: CreateMailchimpAudienceInput;
};


export type MutationCreateMailchimpCampaignArgs = {
  input: CreateMailchimpCampaignInput;
};


export type MutationCreateOfflineDonationArgs = {
  input: CreateOfflineDonationInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationCreateOrderChariotArgs = {
  input: CreateOrderChariotInput;
};


export type MutationCreatePageArgs = {
  input: CreatePageInput;
};


export type MutationCreateSegmentArgs = {
  input: CreateSegmentInput;
};


export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


export type MutationCreateWorkspaceArgs = {
  input: CreateWorkspaceInput;
};


export type MutationDeleteAllocationArgs = {
  input: DeleteAllocationInput;
};


export type MutationDeleteContactArgs = {
  input: DeleteContactInput;
};


export type MutationDeleteContactFromInstitutionArgs = {
  input: DeleteContactFromInstitutionInput;
};


export type MutationDeleteCustomDomainArgs = {
  input: DeleteCustomDomainInput;
};


export type MutationDeleteEmailCampaignArgs = {
  webId: Scalars['ID']['input'];
  workspaceWebId: Scalars['ID']['input'];
};


export type MutationDeleteEmailTemplateArgs = {
  webId: Scalars['ID']['input'];
  workspaceWebId: Scalars['ID']['input'];
};


export type MutationDeleteEmbeddableDonationFormArgs = {
  input: DeleteEmbeddableDonationFormInput;
};


export type MutationDeleteFundraiserArgs = {
  input: DeleteFundraiserInput;
};


export type MutationDeleteInstitutionArgs = {
  input: DeleteInstitutionInput;
};


export type MutationDeleteOfflineDonationArgs = {
  input: DeleteOfflineDonationInput;
};


export type MutationDeleteSegmentArgs = {
  input: DeleteSegmentInput;
};


export type MutationDeleteTagArgs = {
  input: DeleteTagInput;
};


export type MutationDisableMailchimpIntegrationArgs = {
  input: DisableMailchimpIntegrationInput;
};


export type MutationDisconnectConstantContactIntegrationArgs = {
  input: DisconnectConstantContactIntegrationInput;
};


export type MutationDisconnectDoubleTheDonationIntegrationArgs = {
  input: DisconnectDoubleTheDonationIntegrationInput;
};


export type MutationDisconnectFacebookArgs = {
  input: DisconnectFacebookInput;
};


export type MutationDisconnectSalesforceArgs = {
  input: DisconnectSalesforceInput;
};


export type MutationDisconnectTextToDonateArgs = {
  input: DisconnectTextToDonateInput;
};


export type MutationDonorPortalBeginUpdateOrderArgs = {
  input: DonorPortalBeginUpdateOrderInput;
};


export type MutationDonorPortalCancelRecurringDonationArgs = {
  input: DonorPortalCancelRecurringDonationInput;
};


export type MutationDonorPortalEndUpdateOrderArgs = {
  input: DonorPortalEndUpdateOrderInput;
};


export type MutationDonorPortalRestartRecurringDonationArgs = {
  input: DonorPortalRestartRecurringDonationInput;
};


export type MutationDonorPortalUpdateOrderAchArgs = {
  input: DonorPortalUpdateOrderAchInput;
};


export type MutationDonorSignInWithTokenArgs = {
  input: DonorSignInWithTokenInput;
};


export type MutationDonorVerifyEmailArgs = {
  input: DonorVerifyEmailInput;
};


export type MutationDownloadStockImageArgs = {
  input: DownloadStockImageInput;
};


export type MutationDuplicateEmailCampaignArgs = {
  webId: Scalars['ID']['input'];
  workspaceWebId: Scalars['ID']['input'];
};


export type MutationEndConstantContactImportArgs = {
  input: EndConstantContactImportInput;
};


export type MutationEndConstantContactIntegrationConnectArgs = {
  input: EndConstantContactIntegrationConnectInput;
};


export type MutationEndCsvImportArgs = {
  input: EndCsvImportInput;
};


export type MutationEndFacebookConnectArgs = {
  input: EndFacebookConnectInput;
};


export type MutationEndImageUploadArgs = {
  input: EndImageUploadInput;
};


export type MutationEndSalesforceConnectArgs = {
  input: EndSalesforceConnectInput;
};


export type MutationEndStripeConnectArgs = {
  input: EndStripeConnectInput;
};


export type MutationExportContactsArgs = {
  input: ExportContactsInput;
};


export type MutationExportInstitutionsArgs = {
  input: ExportInstitutionsInput;
};


export type MutationExportTransactionsArgs = {
  input: ExportTransactionsInput;
};


export type MutationHideWorkspaceArgs = {
  input: HideWorkspaceInput;
};


export type MutationInitiateMailchimpIntegrationArgs = {
  input: InitiateMailchimpIntegrationInput;
};


export type MutationInviteWorkspaceMemberArgs = {
  input: InviteWorkspaceMemberInput;
};


export type MutationManageBillingArgs = {
  input: ManageBillingInput;
};


export type MutationOpenStripeArgs = {
  input: OpenStripeInput;
};


export type MutationPublishPageArgs = {
  input: PublishPageInput;
};


export type MutationReadAllRepliesArgs = {
  input: ReadAllRepliesInput;
};


export type MutationRefundTransactionArgs = {
  input: RefundTransactionInput;
};


export type MutationRemoveWorkspaceMemberArgs = {
  input: RemoveWorkspaceMemberInput;
};


export type MutationReportRevenuePerWorkspaceArgs = {
  range?: InputMaybe<TDateRange>;
};


export type MutationResendInvitationArgs = {
  input: ResendInvitationInput;
};


export type MutationResendReceiptArgs = {
  input: ResendReceiptInput;
};


export type MutationResetAutomationArgs = {
  input: ResetAutomationInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationScheduleCampaignArgs = {
  input: ScheduleCampaignInput;
};


export type MutationSelectConstantContactListsArgs = {
  input: SelectConstantContactListsInput;
};


export type MutationSendFeedbackArgs = {
  input: SendFeedbackInput;
};


export type MutationSendMailchimpCampaignEmailArgs = {
  input: SendMailchimpCampaignEmailInput;
};


export type MutationSendMessageToEmailAssistantArgs = {
  input: SendMessageToEmailAssistantInput;
};


export type MutationSendPasswordResetEmailArgs = {
  input: SendPasswordResetEmailInput;
};


export type MutationSendTestSmsArgs = {
  input: SendTestSmsInput;
};


export type MutationSetAllocationVisibilityArgs = {
  input: SetAllocationVisibilityInput;
};


export type MutationSetAllowCustomAmountArgs = {
  input: SetAllowCustomAmountInput;
};


export type MutationSetAnonymousArgs = {
  input: SetAnonymousInput;
};


export type MutationSetConstantContactListArgs = {
  input: SetConstantContactListInput;
};


export type MutationSetDefaultDonationRecurrenceIntervalArgs = {
  input: SetDefaultDonationRecurrenceIntervalInput;
};


export type MutationSetDonationTiersOrderArgs = {
  input: SetDonationTiersOrderInput;
};


export type MutationSetPledgeReceivedArgs = {
  input: SetPledgeReceivedInput;
};


export type MutationSetRoleArgs = {
  input: SetRoleInput;
};


export type MutationSetSoftCreditContactArgs = {
  input: SetSoftCreditContactInput;
};


export type MutationSetupTextToDonateArgs = {
  input: SetupTextToDonateInput;
};


export type MutationSignInWithEmailArgs = {
  input: SignInWithEmailInput;
};


export type MutationSignInWithGmailArgs = {
  input: SignInWithGmailInput;
};


export type MutationSignUpWithEmailArgs = {
  input: SignUpWithEmailInput;
};


export type MutationSignUpWithGmailArgs = {
  input: SignUpWithGmailInput;
};


export type MutationTagContactArgs = {
  input: TagContactInput;
};


export type MutationTestRunAutomationArgs = {
  input: TestRunAutomationInput;
};


export type MutationUnarchiveCampaignArgs = {
  input: UnarchiveCampaignInput;
};


export type MutationUnarchivePageArgs = {
  input: UnarchivePageInput;
};


export type MutationUnpublishPageArgs = {
  input: UnpublishPageInput;
};


export type MutationUnscheduleCampaignArgs = {
  input: UnscheduleCampaignInput;
};


export type MutationUnsubscribeArgs = {
  input: UnsubscribeInput;
};


export type MutationUntagContactArgs = {
  input: UntagContactInput;
};


export type MutationUpdateAllocationArgs = {
  input: UpdateAllocationInput;
};


export type MutationUpdateAutomationArgs = {
  input: UpdateAutomationInput;
};


export type MutationUpdateAutomationSettingsArgs = {
  input: UpdateAutomationSettingsInput;
};


export type MutationUpdateCampaignArgs = {
  input: UpdateCampaignInput;
};


export type MutationUpdateCampaignPageArgs = {
  input: UpdateCampaignPageInput;
};


export type MutationUpdateChariotIntegrationArgs = {
  input: UpdateChariotIntegrationInput;
};


export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationUpdateContactNotesArgs = {
  input: UpdateContactNotesInput;
};


export type MutationUpdateCurrentUserArgs = {
  input: UpdateCurrentUserInput;
};


export type MutationUpdateCustomDomainArgs = {
  input: UpdateCustomDomainInput;
};


export type MutationUpdateDonateButtonArgs = {
  input: UpdateDonateButtonInput;
};


export type MutationUpdateDonationTiersArgs = {
  input: UpdateDonationTiersInput;
};


export type MutationUpdateEmailCampaignArgs = {
  name: Scalars['String']['input'];
  webId: Scalars['ID']['input'];
  workspaceWebId: Scalars['ID']['input'];
};


export type MutationUpdateEmailPopupArgs = {
  input: UpdateEmailPopupInput;
};


export type MutationUpdateEmailTemplateArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  webId: Scalars['ID']['input'];
  workspaceWebId: Scalars['ID']['input'];
};


export type MutationUpdateEmbeddableDonationFormArgs = {
  input: UpdateEmbeddableDonationFormInput;
};


export type MutationUpdateFacebookPixelIntegrationArgs = {
  input: UpdateFacebookPixelIntegrationInput;
};


export type MutationUpdateFundraiserArgs = {
  input: UpdateFundraiserInput;
};


export type MutationUpdateGoalsArgs = {
  input: UpdateGoalsInput;
};


export type MutationUpdateGoogleAdsIntegrationArgs = {
  input: UpdateGoogleAdsIntegrationInput;
};


export type MutationUpdateGoogleAnalyticsIntegrationArgs = {
  input: UpdateGoogleAnalyticsIntegrationInput;
};


export type MutationUpdateGoogleTagManagerIntegrationArgs = {
  input: UpdateGoogleTagManagerIntegrationInput;
};


export type MutationUpdateInstitutionArgs = {
  input: UpdateInstitutionInput;
};


export type MutationUpdateInstitutionNotesArgs = {
  input: UpdateInstitutionNotesInput;
};


export type MutationUpdateInvitationArgs = {
  input: UpdateInvitationInput;
};


export type MutationUpdateLogoArgs = {
  input: UpdateLogoInput;
};


export type MutationUpdateMailchimpAudienceArgs = {
  input: UpdateMailchimpAudienceInput;
};


export type MutationUpdateOfflineDonationArgs = {
  input: UpdateOfflineDonationInput;
};


export type MutationUpdatePageArgs = {
  input: UpdatePageInput;
};


export type MutationUpdatePageStoryArgs = {
  input: UpdatePageStoryInput;
};


export type MutationUpdatePrimaryContactArgs = {
  input: UpdateWorkspacePrimaryContactInput;
};


export type MutationUpdateReplyArgs = {
  input: UpdateReplyInput;
};


export type MutationUpdateSalesforceSettingsArgs = {
  input: UpdateSalesforceSettingsInput;
};


export type MutationUpdateSegmentArgs = {
  input: UpdateSegmentInput;
};


export type MutationUpdateSoftCreditsArgs = {
  input: UpdateSoftCreditInput;
};


export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};


export type MutationUpdateTimezoneArgs = {
  input: UpdateTimezoneInput;
};


export type MutationUpdateTransactionNotesArgs = {
  input: UpdateTransactionNotesInput;
};


export type MutationUpdateTransactionPageArgs = {
  input: UpdateTransactionPageInput;
};


export type MutationUpdateWorkspaceArgs = {
  input: UpdateWorkspaceInput;
};


export type MutationUpsertEmbeddableDonationFormArgs = {
  input: UpsertEmbeddableDonationFormInput;
};


export type MutationValidateCampaignArgs = {
  input: ValidateCampaignInput;
};


export type MutationValidatePageArgs = {
  input: ValidatePageInput;
};


export type MutationVerifyBillingTokenArgs = {
  input: VerifyBillingTokenInput;
};


export type MutationVerifyInvitationTokenArgs = {
  input: VerifyInvitationTokenInput;
};


export type MutationVerifyPasswordResetTokenArgs = {
  input: VerifyPasswordResetTokenInput;
};


export type MutationVerifyWorkspaceArgs = {
  input: VerifyWorkspaceInput;
};

export type NonprofitAddress = {
  __typename?: 'NonprofitAddress';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type NonprofitAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type OfflineDonationDateInput = {
  day: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type OfflineOrder = Order & {
  __typename?: 'OfflineOrder';
  allocation?: Maybe<Allocation>;
  amount: Scalars['Int']['output'];
  anonymous: Scalars['Boolean']['output'];
  campaignTitle?: Maybe<Scalars['String']['output']>;
  canceled?: Maybe<Scalars['Date']['output']>;
  category: OrderCategory;
  comment?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Contact>;
  created: Scalars['Date']['output'];
  dedication?: Maybe<Dedication>;
  fundraiser?: Maybe<Fundraiser>;
  id: Scalars['ID']['output'];
  initial: Scalars['Int']['output'];
  institution?: Maybe<Institution>;
  nextPayment?: Maybe<Scalars['Date']['output']>;
  page: Page;
  pledge: Scalars['Boolean']['output'];
  pledgeReceivedAt?: Maybe<Scalars['Date']['output']>;
  recurrenceInterval: DonationRecurrenceInterval;
  recurring: Scalars['Boolean']['output'];
  reply?: Maybe<Reply>;
  softCreditContact?: Maybe<Contact>;
  softCredits: Array<SoftCredit>;
  utm?: Maybe<UtmData>;
};

export enum OfflinePaymentMethodType {
  Cash = 'cash',
  Eft = 'eft',
  InKind = 'in_kind',
  Other = 'other',
  PhysicalCheck = 'physical_check',
  Stock = 'stock'
}

export type OfflineTransaction = Transaction & {
  __typename?: 'OfflineTransaction';
  amount: Scalars['Int']['output'];
  created: Scalars['Date']['output'];
  createdTzString: Scalars['String']['output'];
  detail: PaymentDetailOffline;
  donation: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  intent: TransactionIntent;
  net: Scalars['Int']['output'];
  nonDeductibleAmount: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  order: OfflineOrder;
  processingFee: Scalars['Int']['output'];
  receiptSent?: Maybe<Scalars['Date']['output']>;
  refunded: Scalars['Boolean']['output'];
  status?: Maybe<TransactionStatus>;
  tip: Scalars['Int']['output'];
  type: TransactionType;
};

export type OnlineOrder = Order & {
  __typename?: 'OnlineOrder';
  address: BillingAddress;
  allocation?: Maybe<Allocation>;
  amount: Scalars['Int']['output'];
  anonymous: Scalars['Boolean']['output'];
  campaignTitle?: Maybe<Scalars['String']['output']>;
  canceled?: Maybe<Scalars['Date']['output']>;
  category: OrderCategory;
  comment?: Maybe<Scalars['String']['output']>;
  contact: Contact;
  created: Scalars['Date']['output'];
  dedication?: Maybe<Dedication>;
  donorCoversProcessingFees: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  fundraiser?: Maybe<Fundraiser>;
  id: Scalars['ID']['output'];
  initial: Scalars['Int']['output'];
  nextPayment?: Maybe<Scalars['Date']['output']>;
  page: Page;
  recurrenceInterval: DonationRecurrenceInterval;
  recurring: Scalars['Boolean']['output'];
  reply?: Maybe<Reply>;
  softCreditContact?: Maybe<Contact>;
  softCredits: Array<SoftCredit>;
  utm?: Maybe<UtmData>;
};

export enum OnlinePaymentMethodType {
  BankAccount = 'bank_account',
  Card = 'card',
  DonorAdvisedFund = 'donor_advised_fund'
}

export type OnlineTransaction = BankAccountTransaction | CardTransaction | ChariotTransaction;

export type OpenStripeInput = {
  redirectUri: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type OpenStripeResult = {
  __typename?: 'OpenStripeResult';
  sessionUrl: Scalars['String']['output'];
};

export type Order = {
  allocation?: Maybe<Allocation>;
  amount: Scalars['Int']['output'];
  anonymous: Scalars['Boolean']['output'];
  campaignTitle?: Maybe<Scalars['String']['output']>;
  canceled?: Maybe<Scalars['Date']['output']>;
  category: OrderCategory;
  comment?: Maybe<Scalars['String']['output']>;
  created: Scalars['Date']['output'];
  dedication?: Maybe<Dedication>;
  fundraiser?: Maybe<Fundraiser>;
  id: Scalars['ID']['output'];
  initial: Scalars['Int']['output'];
  nextPayment?: Maybe<Scalars['Date']['output']>;
  page: Page;
  recurrenceInterval: DonationRecurrenceInterval;
  recurring: Scalars['Boolean']['output'];
  reply?: Maybe<Reply>;
  softCreditContact?: Maybe<Contact>;
  softCredits: Array<SoftCredit>;
  utm?: Maybe<UtmData>;
};

export type OrderCalculation = {
  __typename?: 'OrderCalculation';
  amount: Scalars['Int']['output'];
  tip: Scalars['Int']['output'];
  withCoveringFees: OrderCalculationResult;
  withoutCoveringFees: OrderCalculationResult;
};

export type OrderCalculationFee = {
  __typename?: 'OrderCalculationFee';
  fixed: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  variable: Scalars['Float']['output'];
};

export type OrderCalculationInput = {
  amount: Scalars['Int']['input'];
  pageId: Scalars['ID']['input'];
  paymentMethod: OnlinePaymentMethodType;
  tip: Scalars['Int']['input'];
};

export type OrderCalculationResult = {
  __typename?: 'OrderCalculationResult';
  fee: Scalars['Int']['output'];
  gross: Scalars['Int']['output'];
  net: Scalars['Int']['output'];
};

export enum OrderCategory {
  Offline = 'offline',
  Online = 'online'
}

export type OrderDetail = {
  __typename?: 'OrderDetail';
  firstName: Scalars['String']['output'];
  isAnonymous: Scalars['Boolean']['output'];
};

export type Page = {
  __typename?: 'Page';
  allocations: Array<PublicAllocation>;
  allowCustomAmount: Scalars['Boolean']['output'];
  chariotConnectId?: Maybe<Scalars['String']['output']>;
  color: Scalars['String']['output'];
  created: Scalars['Date']['output'];
  currentAmount: Scalars['Int']['output'];
  customDomain?: Maybe<CustomDomain>;
  defaultDonationRecurrenceInterval: DonationRecurrenceInterval;
  defaultDonorCoverFeesValue: Scalars['Boolean']['output'];
  donationTiers: Array<DonationTier>;
  donationTiersOrder: DonationTiersOrder;
  embeddableForm?: Maybe<EmbeddableForm>;
  enableTipping: Scalars['Boolean']['output'];
  facebookPixelId?: Maybe<Scalars['String']['output']>;
  fundraisers: Array<Fundraiser>;
  fundraisersTitle: Scalars['String']['output'];
  goals: Array<Goal>;
  googleAdsConversionId?: Maybe<Scalars['String']['output']>;
  googleAdsConversionLabel?: Maybe<Scalars['String']['output']>;
  googleAnalyticsId?: Maybe<Scalars['String']['output']>;
  googleTagManagerId?: Maybe<Scalars['String']['output']>;
  hasImage: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  nonprofit: PageNonprofit;
  pageFeatures: PageFeatures;
  pageUrl?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status: PageStatus;
  story?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: PageType;
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export type PageFeatures = {
  __typename?: 'PageFeatures';
  addressForm: Scalars['Boolean']['output'];
  allocations: Scalars['Boolean']['output'];
  chariotButton: Scalars['Boolean']['output'];
  communityTab: Scalars['Boolean']['output'];
  donationAmount: Scalars['Boolean']['output'];
  donorCoversFees: Scalars['Boolean']['output'];
  peerToPeerTab: Scalars['Boolean']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PageInput = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type PageNonprofit = {
  __typename?: 'PageNonprofit';
  defaultAllocation?: Maybe<PublicAllocation>;
  doubleTheDonationPublicKey?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  taxId: Scalars['String']['output'];
  taxNote: Scalars['String']['output'];
  tipNote: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type PageSegmentValue = {
  __typename?: 'PageSegmentValue';
  operator: SegmentConditionOperator;
  type: SegmentConditionType;
  value: Array<Page>;
};

export enum PageStatus {
  Archived = 'archived',
  Closed = 'closed',
  Draft = 'draft',
  Published = 'published'
}

export enum PageType {
  DonationPage = 'donation_page',
  FundraisingPage = 'fundraising_page'
}

export enum PaidFeatures {
  AdvancedAnalytics = 'AdvancedAnalytics',
  Automation = 'Automation',
  Campaigns = 'Campaigns',
  ConstantContactIntegration = 'ConstantContactIntegration',
  CreateContacts = 'CreateContacts',
  CustomDomains = 'CustomDomains',
  CustomSenderInformation = 'CustomSenderInformation',
  DoubleTheDonationIntegration = 'DoubleTheDonationIntegration',
  EmbeddableDonationFormCustomization = 'EmbeddableDonationFormCustomization',
  ExportContacts = 'ExportContacts',
  FacebookPixelIntegration = 'FacebookPixelIntegration',
  GoogleAdsIntegration = 'GoogleAdsIntegration',
  GoogleAnalyticsIntegration = 'GoogleAnalyticsIntegration',
  GoogleTagManagerIntegration = 'GoogleTagManagerIntegration',
  ImportContacts = 'ImportContacts',
  Institutions = 'Institutions',
  MailchimpIntegration = 'MailchimpIntegration',
  OfflineDonations = 'OfflineDonations',
  PeerToPeerFundraisingPages = 'PeerToPeerFundraisingPages',
  PhoneNumbers = 'PhoneNumbers',
  Pledges = 'Pledges',
  QrCodeCustomization = 'QrCodeCustomization',
  SalesforceIntegration = 'SalesforceIntegration',
  Segmentation = 'Segmentation',
  SoftCredits = 'SoftCredits',
  Tagging = 'Tagging',
  TextToDonate = 'TextToDonate',
  UnlimitedWorkspaceMembers = 'UnlimitedWorkspaceMembers',
  UserRoles = 'UserRoles',
  WorkspaceMembers = 'WorkspaceMembers'
}

export type PaymentDetail = PaymentDetailBankAccount | PaymentDetailCard | PaymentDetailChariot | PaymentDetailOffline;

export type PaymentDetailBankAccount = {
  __typename?: 'PaymentDetailBankAccount';
  bankName: Scalars['String']['output'];
  last4: Scalars['String']['output'];
  routingNumber: Scalars['String']['output'];
};

export type PaymentDetailCard = {
  __typename?: 'PaymentDetailCard';
  brand: Scalars['String']['output'];
  last4: Scalars['String']['output'];
};

export type PaymentDetailChariot = {
  __typename?: 'PaymentDetailChariot';
  fundId: Scalars['String']['output'];
  fundName?: Maybe<Scalars['String']['output']>;
  grantId: Scalars['String']['output'];
  userFriendlyId: Scalars['String']['output'];
};

export type PaymentDetailOffline = {
  __typename?: 'PaymentDetailOffline';
  type: OfflinePaymentMethodType;
};

export type Payout = {
  __typename?: 'Payout';
  amount: Scalars['Int']['output'];
  created: Scalars['Date']['output'];
  createdTzString: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  payoutMethod: PayoutMethod;
};

export type PayoutBankAccount = {
  __typename?: 'PayoutBankAccount';
  bankName: Scalars['String']['output'];
  last4: Scalars['String']['output'];
};

export type PayoutCard = {
  __typename?: 'PayoutCard';
  brand: Scalars['String']['output'];
  last4: Scalars['String']['output'];
};

export type PayoutMethod = PayoutBankAccount | PayoutCard;

export enum PayoutType {
  BankAccount = 'bankAccount',
  Card = 'card'
}

export type Post = {
  __typename?: 'Post';
  amount: Scalars['Int']['output'];
  anonymous: Scalars['Boolean']['output'];
  author?: Maybe<PostAuthor>;
  comment?: Maybe<Scalars['String']['output']>;
  created: Scalars['Date']['output'];
  dedication?: Maybe<Dedication>;
  donation: Scalars['Int']['output'];
  fundraiser?: Maybe<Fundraiser>;
  id: Scalars['ID']['output'];
  recurrenceInterval: DonationRecurrenceInterval;
  reply?: Maybe<PostReply>;
};

export type PostAuthor = {
  __typename?: 'PostAuthor';
  name: Scalars['String']['output'];
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
};

export type PostReply = {
  __typename?: 'PostReply';
  author: PostAuthor;
  created: Scalars['Date']['output'];
  text: Scalars['String']['output'];
};

export type PublicAllocation = {
  __typename?: 'PublicAllocation';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type PublishPageInput = {
  id: Scalars['ID']['input'];
  slug: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  allocations: Array<Allocation>;
  automation: Automation;
  automationSettings: AutomationSettings;
  automations: AutomationList;
  billingPlans: BillingPlans;
  campaign: Campaign;
  campaigns: Array<Campaign>;
  churnReport: ChurnReport;
  churnReportToCsv: CsvResult;
  community: CommunityList;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  constantContactLists: ConstantContactListsResult;
  contact: Contact;
  contacts: ContactList;
  currentUser: CurrentUser;
  currentUserOrders: Array<CurrentUserOrder>;
  customDomain?: Maybe<CustomDomain>;
  donateButton: DonateButton;
  donationPage: Page;
  emailCampaign: EmailCampaign;
  emailCampaigns: Array<EmailCampaign>;
  emailFunnel: EmailFunnel;
  emailPopup: EmailPopup;
  emailTemplate: EmailTemplate;
  emailTemplates: Array<EmailTemplate>;
  exportDownloadLink: ExportDownloadResult;
  getEmbeddableDonationFormDetail: GetEmbeddableDonationFormDetailResult;
  getEmbeddableDonationFormDetailByPageWebId?: Maybe<GetEmbeddableDonationFormDetailByPageWebIdResult>;
  images: Array<Image>;
  institution: Institution;
  institutions: InstitutionList;
  institutionsAndContacts: InstitutionsAndContactsResult;
  invitations: Array<Invitation>;
  legacyDemo: LegacyDemo;
  listEmbeddableDonationForms: ListEmbeddableDonationFormsResult;
  listMailchimpAudiences: ListMailchimpAudiencesOutput;
  orderCalculation: OrderCalculation;
  orderDetail: OrderDetail;
  page: Page;
  pageBySlug: Page;
  pages: Array<Page>;
  payouts: Array<Payout>;
  recurringRevenueReport: RecurringRevenueReportResult;
  recurringRevenueReportToCsv: CsvResult;
  replyTasks: ReplyTaskList;
  /**
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  requestContactsFinanceExport: ExportContactsFinanceResult;
  requestCustomerTransactionReport: CustomerTransactionReportResult;
  retention: RetentionChart;
  revenueReport: RevenueReportResult;
  revenueReportToCsv: CsvResult;
  /**
   * ## Errors
   *
   * #### requires_kindest_pro
   * This feature requires Kindest Pro
   */
  salesforceMetadata: SalesforceMetadataResult;
  segmentation: SegmentationChart;
  segments: Array<SegmentInformation>;
  smsFunnel: SmsFunnel;
  stockImages: StockImageList;
  subscriptionReport: SubscriptionReportResult;
  subscriptionReportToCsv: CsvResult;
  /**
   * ## Errors
   *
   * #### invalid_ubsubscribe_token
   * Invalid unsubscribe token
   */
  subscriptionStatus: UserSubscriptionStatus;
  tags: Array<TagInformation>;
  textToDonate: TextToDonate;
  timezones: Array<Timezone>;
  transaction: Transaction;
  transactionalRevenueReport: TransactionalRevenueReportResult;
  transactionalRevenueReportToCsv: CsvResult;
  transactions: TransactionList;
  twilioPhoneNumbers: Array<TwilioPhoneNumber>;
  unreadReplyCount: UnreadReplyCount;
  validateCancelProSubscription: ValidateCancelProSubscriptionResult;
  weekdays: Array<Weekday>;
  workspace: Workspace;
  workspaceMembers: Array<WorkspaceMember>;
  workspaces: WorkspaceList;
};


export type QueryAllocationsArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryAutomationArgs = {
  type: AutomationType;
  workspaceId: Scalars['ID']['input'];
};


export type QueryAutomationSettingsArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryAutomationsArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCampaignsArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryChurnReportArgs = {
  page?: InputMaybe<PageInput>;
  withCanceled: Scalars['Boolean']['input'];
};


export type QueryChurnReportToCsvArgs = {
  withCanceled: Scalars['Boolean']['input'];
};


export type QueryCommunityArgs = {
  fundraiserId?: InputMaybe<Scalars['ID']['input']>;
  page: CommunityListPageInput;
  pageSlug: Scalars['String']['input'];
};


export type QueryConstantContactListsArgs = {
  input: ConstantContactListsInput;
};


export type QueryContactArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContactsArgs = {
  order: ContactListOrderInput;
  page: ContactListPageInput;
  search?: InputMaybe<Scalars['String']['input']>;
  segment?: InputMaybe<SegmentConditionsInput>;
  workspaceId: Scalars['ID']['input'];
};


export type QueryCustomDomainArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryDonateButtonArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryDonationPageArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryEmailCampaignArgs = {
  webId: Scalars['ID']['input'];
  workspaceWebId: Scalars['ID']['input'];
};


export type QueryEmailCampaignsArgs = {
  workspaceWebId: Scalars['ID']['input'];
};


export type QueryEmailFunnelArgs = {
  filter?: InputMaybe<AnalyticsFilterInput>;
  workspaceId: Scalars['ID']['input'];
};


export type QueryEmailPopupArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryEmailTemplateArgs = {
  webId: Scalars['ID']['input'];
  workspaceWebId: Scalars['ID']['input'];
};


export type QueryEmailTemplatesArgs = {
  workspaceWebId: Scalars['ID']['input'];
};


export type QueryExportDownloadLinkArgs = {
  input: ExportDownloadInput;
};


export type QueryGetEmbeddableDonationFormDetailArgs = {
  input: GetEmbeddableDonationFormDetailInput;
};


export type QueryGetEmbeddableDonationFormDetailByPageWebIdArgs = {
  input: GetEmbeddableDonationFormDetailByPageWebIdInput;
};


export type QueryImagesArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryInstitutionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInstitutionsArgs = {
  order: InstitutionListOrderInput;
  page: InstitutionListPageInput;
  search?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['ID']['input'];
};


export type QueryInstitutionsAndContactsArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryInvitationsArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryLegacyDemoArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryListEmbeddableDonationFormsArgs = {
  input: ListEmbeddableDonationFormsInput;
};


export type QueryListMailchimpAudiencesArgs = {
  input: ListMailchimpAudiencesInput;
};


export type QueryOrderCalculationArgs = {
  input: OrderCalculationInput;
};


export type QueryOrderDetailArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPageBySlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryPagesArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryPayoutsArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryRecurringRevenueReportArgs = {
  filter?: InputMaybe<RecurringRevenueFilterInput>;
  page?: InputMaybe<PageInput>;
};


export type QueryRecurringRevenueReportToCsvArgs = {
  filter?: InputMaybe<RecurringRevenueFilterInput>;
};


export type QueryReplyTasksArgs = {
  filter?: InputMaybe<ReplyTaskFilterInput>;
  page: ReplyTaskPageInput;
  workspaceId: Scalars['ID']['input'];
};


export type QueryRequestContactsFinanceExportArgs = {
  input: ExportContactsInput;
};


export type QueryRequestCustomerTransactionReportArgs = {
  input: ExportTransactionsInput;
};


export type QueryRetentionArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryRevenueReportArgs = {
  filter?: InputMaybe<RevenueReportFilterInput>;
  page: PageInput;
};


export type QueryRevenueReportToCsvArgs = {
  filter?: InputMaybe<RevenueReportFilterInput>;
};


export type QuerySalesforceMetadataArgs = {
  input: SalesforceMetadataInput;
};


export type QuerySegmentationArgs = {
  filter?: InputMaybe<AnalyticsFilterInput>;
  workspaceId: Scalars['ID']['input'];
};


export type QuerySegmentsArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QuerySmsFunnelArgs = {
  filter?: InputMaybe<AnalyticsFilterInput>;
  workspaceId: Scalars['ID']['input'];
};


export type QueryStockImagesArgs = {
  page: StockImageListPageInput;
  query: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};


export type QuerySubscriptionReportArgs = {
  page?: InputMaybe<PageInput>;
};


export type QuerySubscriptionStatusArgs = {
  contactId: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};


export type QueryTagsArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryTextToDonateArgs = {
  input: TextToDonateInput;
};


export type QueryTimezonesArgs = {
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryTransactionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTransactionalRevenueReportArgs = {
  filter?: InputMaybe<TransactionalRevenueFilterInput>;
  page?: InputMaybe<PageInput>;
};


export type QueryTransactionalRevenueReportToCsvArgs = {
  filter?: InputMaybe<TransactionalRevenueFilterInput>;
};


export type QueryTransactionsArgs = {
  filter?: InputMaybe<TransactionListFilterInput>;
  order: TransactionListOrderInput;
  page: TransactionListPageInput;
  search?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['ID']['input'];
};


export type QueryTwilioPhoneNumbersArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryUnreadReplyCountArgs = {
  input: UnreadReplyCountInput;
};


export type QueryValidateCancelProSubscriptionArgs = {
  input: ValidateCancelProSubscriptionInput;
};


export type QueryWeekdaysArgs = {
  filter?: InputMaybe<AnalyticsFilterInput>;
  workspaceId: Scalars['ID']['input'];
};


export type QueryWorkspaceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWorkspaceMembersArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryWorkspacesArgs = {
  demo?: InputMaybe<Scalars['Boolean']['input']>;
  order: WorkspaceListOrderInput;
  page: WorkspaceListPageInput;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ReadAllRepliesInput = {
  workspaceId: Scalars['ID']['input'];
};

export type Receipt = {
  __typename?: 'Receipt';
  amount: Scalars['Int']['output'];
  created: Scalars['Date']['output'];
  detail: PaymentDetail;
  id: Scalars['ID']['output'];
  paymentMethod: CurrentUserPaymentMethodType;
};

export type RecordType = {
  __typename?: 'RecordType';
  name: Scalars['String']['output'];
};

export type RecurringRevenueFilterInput = {
  month: Scalars['Int']['input'];
  revenueType: RevenueType;
  year: Scalars['Int']['input'];
};

export type RecurringRevenueItem = {
  __typename?: 'RecurringRevenueItem';
  amount: Scalars['Int']['output'];
  chargeId: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
  nonprofitName: Scalars['String']['output'];
  period: Scalars['String']['output'];
  refunded: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  workspaceHubSpotConsultant?: Maybe<Scalars['String']['output']>;
  workspaceHubSpotOwnerId?: Maybe<Scalars['Int']['output']>;
  workspaceId: Scalars['ID']['output'];
  workspaceName: Scalars['String']['output'];
};

export type RecurringRevenueReportResult = {
  __typename?: 'RecurringRevenueReportResult';
  pageInfo?: Maybe<PageInfo>;
  recurringRevenueItems: Array<RecurringRevenueItem>;
};

export type RefundTransactionInput = {
  id: Scalars['ID']['input'];
};

export type RemoveWorkspaceMemberInput = {
  memberId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type RemoveWorkspaceMemberResult = {
  __typename?: 'RemoveWorkspaceMemberResult';
  id: Scalars['ID']['output'];
};

export type Reply = {
  __typename?: 'Reply';
  text: Scalars['String']['output'];
  user: ReplyUser;
};

export type ReplyTask = {
  __typename?: 'ReplyTask';
  id: Scalars['ID']['output'];
  order: Order;
  reply?: Maybe<Reply>;
};

export type ReplyTaskFilterInput = {
  type?: InputMaybe<ReplyTaskFilterType>;
};

export enum ReplyTaskFilterType {
  Completed = 'Completed',
  Inbox = 'Inbox'
}

export type ReplyTaskList = {
  __typename?: 'ReplyTaskList';
  pageInfo: ReplyTaskListPageInfo;
  replyTasks: Array<ReplyTask>;
};

export type ReplyTaskListPageInfo = {
  __typename?: 'ReplyTaskListPageInfo';
  cursor?: Maybe<Scalars['String']['output']>;
  pageSize: Scalars['Int']['output'];
};

export type ReplyTaskPageInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize: Scalars['Int']['input'];
};

export type ReplyUser = {
  __typename?: 'ReplyUser';
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
};

export type ResendInvitationInput = {
  id: Scalars['ID']['input'];
};

export type ResendInvitationResult = {
  __typename?: 'ResendInvitationResult';
  success: Scalars['Boolean']['output'];
};

export type ResendReceiptInput = {
  transactionId: Scalars['ID']['input'];
};

export type ResetAutomationInput = {
  type: AutomationType;
  workspaceId: Scalars['ID']['input'];
};

export type ResetPasswordInput = {
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
  tokenId: Scalars['ID']['input'];
};

export type RetentionChart = {
  __typename?: 'RetentionChart';
  cohorts: Array<RetentionChartCohort>;
};

export type RetentionChartCohort = {
  __typename?: 'RetentionChartCohort';
  month: Scalars['Int']['output'];
  periods: Array<Scalars['Int']['output']>;
  size: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type RevenueItem = {
  __typename?: 'RevenueItem';
  donationsSum: Scalars['Int']['output'];
  feesSum: Scalars['Int']['output'];
  kindestProPaymentsSum: Scalars['Int']['output'];
  tipsSum: Scalars['Int']['output'];
  workspaceBillingCanceled?: Maybe<Scalars['Date']['output']>;
  workspaceHubSpotConsultant?: Maybe<Scalars['String']['output']>;
  workspaceHubSpotOwnerId?: Maybe<Scalars['Int']['output']>;
  workspaceId: Scalars['ID']['output'];
  workspaceName: Scalars['String']['output'];
  workspaceNonprofitName?: Maybe<Scalars['String']['output']>;
};

export type RevenueReportFilterInput = {
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type RevenueReportResult = {
  __typename?: 'RevenueReportResult';
  pageInfo?: Maybe<PageInfo>;
  revenueItems: Array<RevenueItem>;
};

export enum RevenueType {
  All = 'all',
  New = 'new',
  Recurring = 'recurring'
}

export enum Role {
  Admin = 'admin',
  Assistant = 'assistant',
  Creator = 'creator',
  Finance = 'finance',
  User = 'user'
}

export type SmsFunnel = {
  __typename?: 'SMSFunnel';
  clicked: Scalars['Int']['output'];
  delivered: Scalars['Int']['output'];
  donated: Scalars['Int']['output'];
  sent: Scalars['Int']['output'];
};

export type SalesforceMetadataInput = {
  workspaceId: Scalars['ID']['input'];
};

export type SalesforceMetadataResult = {
  __typename?: 'SalesforceMetadataResult';
  accountRecordTypes: Array<RecordType>;
  campaignRecordTypes: Array<RecordType>;
  contactRecordTypes: Array<RecordType>;
  opportunityRecordTypes: Array<RecordType>;
  stageNames: Array<Scalars['String']['output']>;
};

export type ScheduleCampaignInput = {
  date: CampaignDateInput;
  id: Scalars['ID']['input'];
  segmentId?: InputMaybe<Scalars['ID']['input']>;
  time: CampaignTimeInput;
};

export type Segment = {
  __typename?: 'Segment';
  conditions: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type SegmentCondition = BooleanSegmentValue | DateSegmentValue | IntSegmentValue | PageSegmentValue | TagSegmentValue;

export enum SegmentConditionOperator {
  Contains = 'contains',
  Equal = 'equal',
  Gte = 'gte',
  Lte = 'lte',
  None = 'none'
}

export enum SegmentConditionType {
  IsRecurringDonor = 'isRecurringDonor',
  LastTransactionDate = 'lastTransactionDate',
  Lybunty = 'lybunty',
  Page = 'page',
  Sybunty = 'sybunty',
  Tag = 'tag',
  TransactionAmount = 'transactionAmount'
}

export type SegmentConditionValueInput = {
  operator: SegmentConditionOperator;
  type: SegmentConditionType;
  value: Scalars['String']['input'];
};

export type SegmentConditionsInput = {
  conditions?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SegmentDate = {
  __typename?: 'SegmentDate';
  day: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type SegmentInformation = {
  __typename?: 'SegmentInformation';
  contactCount: Scalars['Int']['output'];
  segment: Segment;
};

export type SegmentationChart = {
  __typename?: 'SegmentationChart';
  dataPoints: Array<SegmentationDataPoint>;
  firstTransaction?: Maybe<Scalars['Date']['output']>;
  sources: AnalyticsSources;
};

export type SegmentationChartDate = {
  __typename?: 'SegmentationChartDate';
  day: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type SegmentationDataPoint = {
  __typename?: 'SegmentationDataPoint';
  date: SegmentationChartDate;
  sources: AnalyticsSources;
};

export type SelectConstantContactListsInput = {
  constantContactImportId: Scalars['ID']['input'];
  lists: Array<Scalars['ID']['input']>;
};

export type SelectConstantContactListsResult = {
  __typename?: 'SelectConstantContactListsResult';
  constantContactImportId: Scalars['ID']['output'];
  sampleContacts: Array<ConstantContactSample>;
};

export type SendFeedbackInput = {
  text: Scalars['String']['input'];
};

export type SendFeedbackResult = {
  __typename?: 'SendFeedbackResult';
  success: Scalars['Boolean']['output'];
};

export type SendMailchimpCampaignEmailInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['String']['input'];
  htmlBody?: InputMaybe<Scalars['String']['input']>;
  recipientEmails: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type SendMailchimpCampaignEmailOutput = {
  __typename?: 'SendMailchimpCampaignEmailOutput';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type SendMessageToEmailAssistantInput = {
  content: Scalars['String']['input'];
  emailId?: InputMaybe<Scalars['String']['input']>;
  generateEmail: Scalars['Boolean']['input'];
  role: Role;
  threadId: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type SendMessageToEmailAssistantResult = {
  __typename?: 'SendMessageToEmailAssistantResult';
  action: Scalars['String']['output'];
  emailId: Scalars['String']['output'];
  emails: Array<EmailDetail>;
  followUpMessage: Scalars['String']['output'];
};

export type SendPasswordChangeEmailResult = {
  __typename?: 'SendPasswordChangeEmailResult';
  success: Scalars['Boolean']['output'];
};

export type SendPasswordResetEmailInput = {
  email: Scalars['String']['input'];
  recaptchaResponse: Scalars['String']['input'];
};

export type SendPasswordResetEmailResult = {
  __typename?: 'SendPasswordResetEmailResult';
  success: Scalars['Boolean']['output'];
};

export type SendTestSmsInput = {
  campaignId: Scalars['ID']['input'];
  phone: Scalars['String']['input'];
};

export type SendTestSmsResult = {
  __typename?: 'SendTestSmsResult';
  success: Scalars['Boolean']['output'];
};

export type SetAllocationVisibilityInput = {
  id: Scalars['ID']['input'];
  isVisible: Scalars['Boolean']['input'];
};

export type SetAllowCustomAmountInput = {
  allowCustomAmount: Scalars['Boolean']['input'];
  pageId: Scalars['ID']['input'];
};

export type SetAnonymousInput = {
  anonymous: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};

export type SetConstantContactListInput = {
  existingListId: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type SetDefaultDonationRecurrenceIntervalInput = {
  defaultDonationRecurrenceInterval: DonationRecurrenceInterval;
  pageId: Scalars['ID']['input'];
};

export type SetDonationTiersOrderInput = {
  order: DonationTiersOrder;
  pageId: Scalars['ID']['input'];
};

export type SetPledgeReceivedInput = {
  id: Scalars['ID']['input'];
  isReceived: Scalars['Boolean']['input'];
};

export type SetRoleInput = {
  memberId: Scalars['ID']['input'];
  role: Role;
  workspaceId: Scalars['ID']['input'];
};

export type SetSoftCreditContactInput = {
  contactId?: InputMaybe<Scalars['ID']['input']>;
  orderId: Scalars['ID']['input'];
};

export type SetupTextToDonateInput = {
  donationCode: Scalars['String']['input'];
  pageId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type SignInWithEmailInput = {
  email: Scalars['String']['input'];
  invitation?: InputMaybe<InvitationInput>;
  password: Scalars['String']['input'];
};

export type SignInWithGmailInput = {
  idToken: Scalars['String']['input'];
  invitation?: InputMaybe<InvitationInput>;
};

export type SignUpWithEmailInput = {
  email: Scalars['String']['input'];
  invitation?: InputMaybe<InvitationInput>;
  password: Scalars['String']['input'];
  recaptchaResponse: Scalars['String']['input'];
};

export type SignUpWithGmailInput = {
  idToken: Scalars['String']['input'];
  invitation?: InputMaybe<InvitationInput>;
};

export type Sms = {
  __typename?: 'Sms';
  phoneNumberCount: Scalars['Int']['output'];
  text: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type SmsAutomation = {
  __typename?: 'SmsAutomation';
  data: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  type: AutomationType;
};

export type SmsCampaignInformation = {
  __typename?: 'SmsCampaignInformation';
  contactsCount: Scalars['Int']['output'];
  phoneNumberCount: Scalars['Int']['output'];
};

export enum SmsSubscriptionStatus {
  Subscribed = 'subscribed',
  Unsubscribed = 'unsubscribed'
}

export type SoftCredit = {
  type: SoftCreditType;
};

export type SoftCreditAbstract = {
  softCreditId: Scalars['ID']['input'];
  type: SoftCreditType;
};

export type SoftCreditContact = SoftCredit & {
  __typename?: 'SoftCreditContact';
  contact: Contact;
  type: SoftCreditType;
};

export type SoftCreditInstitution = SoftCredit & {
  __typename?: 'SoftCreditInstitution';
  institution: Institution;
  type: SoftCreditType;
};

export enum SoftCreditType {
  Contact = 'contact',
  Institution = 'institution'
}

export type StockImage = {
  __typename?: 'StockImage';
  author: StockImageAuthor;
  id: Scalars['ID']['output'];
  url: StockImageUrl;
};

export type StockImageAuthor = {
  __typename?: 'StockImageAuthor';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type StockImageList = {
  __typename?: 'StockImageList';
  images: Array<StockImage>;
  pageInfo: TransactionListPageInfo;
};

export type StockImageListPageInfo = {
  __typename?: 'StockImageListPageInfo';
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type StockImageListPageInput = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type StockImageUrl = {
  __typename?: 'StockImageUrl';
  large: Scalars['String']['output'];
  thumbnail: Scalars['String']['output'];
};

export enum StripeAccountType {
  Custom = 'custom',
  Express = 'express',
  Standard = 'standard'
}

export enum StripePaymentMethodType {
  BankAccount = 'bank_account',
  Card = 'card'
}

export type Subscription = {
  __typename?: 'Subscription';
  emailAssistantResponse?: Maybe<EmailAssistantResponse>;
};


export type SubscriptionEmailAssistantResponseArgs = {
  threadId: Scalars['String']['input'];
};

export type SubscriptionItem = {
  __typename?: 'SubscriptionItem';
  billingCanceled?: Maybe<Scalars['Date']['output']>;
  billingChurned?: Maybe<Scalars['Date']['output']>;
  billingStarted?: Maybe<Scalars['Date']['output']>;
  donation: Scalars['Int']['output'];
  enabledPaidFeatures: Array<PaidFeatures>;
  lastPaymentDate?: Maybe<Scalars['Date']['output']>;
  nextPaymentDate?: Maybe<Scalars['Date']['output']>;
  nonprofitName: Scalars['String']['output'];
  period?: Maybe<Scalars['String']['output']>;
  workspaceHubSpotConsultant?: Maybe<Scalars['String']['output']>;
  workspaceHubSpotOwnerId?: Maybe<Scalars['String']['output']>;
  workspaceId: Scalars['ID']['output'];
  workspaceName: Scalars['String']['output'];
};

export type SubscriptionReportResult = {
  __typename?: 'SubscriptionReportResult';
  pageInfo?: Maybe<PageInfo>;
  subscriptionItems: Array<SubscriptionItem>;
};

export type SubscriptionStatus = {
  __typename?: 'SubscriptionStatus';
  billingPlan: BillingPlanName;
  billingPlans: BillingPlans;
  sessionId: Scalars['String']['output'];
  workspaceId: Scalars['ID']['output'];
};

export type TDateRange = {
  end?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
};

export type TResult = {
  __typename?: 'TResult';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Tag = {
  __typename?: 'Tag';
  color: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type TagContactInput = {
  contactId: Scalars['ID']['input'];
  tagId: Scalars['ID']['input'];
};

export type TagInformation = {
  __typename?: 'TagInformation';
  contactCount: Scalars['Int']['output'];
  tag: Tag;
  tagSegments: Array<TagSegment>;
};

export type TagSegment = {
  __typename?: 'TagSegment';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type TagSegmentValue = {
  __typename?: 'TagSegmentValue';
  operator: SegmentConditionOperator;
  type: SegmentConditionType;
  value: Array<Tag>;
};

export type TestRunAutomationInput = {
  recipientEmail?: InputMaybe<Scalars['String']['input']>;
  recipientPhone?: InputMaybe<Scalars['String']['input']>;
  type: AutomationType;
  workspaceId: Scalars['ID']['input'];
};

export type TestRunAutomationResult = {
  __typename?: 'TestRunAutomationResult';
  success: Scalars['Boolean']['output'];
};

export type TextToDonate = {
  __typename?: 'TextToDonate';
  donationCode?: Maybe<Scalars['String']['output']>;
  page?: Maybe<Page>;
  shortCodePhoneNumber: Scalars['String']['output'];
};

export type TextToDonateInput = {
  workspaceId: Scalars['ID']['input'];
};

export type ThreadMessage = {
  __typename?: 'ThreadMessage';
  content: Scalars['String']['output'];
  role: Scalars['String']['output'];
};

export type Timezone = {
  __typename?: 'Timezone';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type Transaction = {
  amount: Scalars['Int']['output'];
  created: Scalars['Date']['output'];
  createdTzString: Scalars['String']['output'];
  donation: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  intent: TransactionIntent;
  net: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  processingFee: Scalars['Int']['output'];
  receiptSent?: Maybe<Scalars['Date']['output']>;
  refunded: Scalars['Boolean']['output'];
  status?: Maybe<TransactionStatus>;
  tip: Scalars['Int']['output'];
  type: TransactionType;
};

export enum TransactionIntent {
  Donation = 'donation',
  Refund = 'refund'
}

export type TransactionList = {
  __typename?: 'TransactionList';
  pageInfo: TransactionListPageInfo;
  transactions: Array<TransactionListItem>;
};

export type TransactionListFilterDateInput = {
  day: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TransactionListFilterInput = {
  campaignId?: InputMaybe<Scalars['ID']['input']>;
  createdFrom?: InputMaybe<TransactionListFilterDateInput>;
  createdTo?: InputMaybe<TransactionListFilterDateInput>;
  maxAmount?: InputMaybe<Scalars['Int']['input']>;
  minAmount?: InputMaybe<Scalars['Int']['input']>;
  pageId?: InputMaybe<Scalars['ID']['input']>;
  paymentType?: InputMaybe<TransactionType>;
  recurring?: InputMaybe<TransactionListFilterRecurringInput>;
};

export enum TransactionListFilterRecurringInput {
  Monthly = 'Monthly',
  MonthlyActive = 'MonthlyActive',
  MonthlyCanceled = 'MonthlyCanceled',
  OneTime = 'OneTime',
  Quarterly = 'Quarterly',
  QuarterlyActive = 'QuarterlyActive',
  QuarterlyCanceled = 'QuarterlyCanceled',
  Yearly = 'Yearly',
  YearlyActive = 'YearlyActive',
  YearlyCanceled = 'YearlyCanceled'
}

export type TransactionListItem = {
  __typename?: 'TransactionListItem';
  amount: Scalars['Int']['output'];
  created: Scalars['Date']['output'];
  createdTzString: Scalars['String']['output'];
  donation: Scalars['Int']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  intent: TransactionIntent;
  name: Scalars['String']['output'];
  net: Scalars['Int']['output'];
  recurrenceInterval: DonationRecurrenceInterval;
  status: TransactionStatus;
  type: TransactionType;
};

export enum TransactionListOrderBy {
  Amount = 'amount',
  Created = 'created',
  Donation = 'donation',
  Email = 'email',
  Name = 'name',
  Net = 'net',
  PaymentType = 'paymentType',
  RecurrenceInterval = 'recurrenceInterval',
  UtmMedium = 'utmMedium'
}

export enum TransactionListOrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type TransactionListOrderInput = {
  by: TransactionListOrderBy;
  direction: TransactionListOrderDirection;
};

export type TransactionListPageInfo = {
  __typename?: 'TransactionListPageInfo';
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type TransactionListPageInput = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export enum TransactionStatus {
  Pending = 'pending',
  Refunded = 'refunded',
  Succeeded = 'succeeded'
}

export enum TransactionType {
  BankAccount = 'bank_account',
  Card = 'card',
  DonorAdvisedFund = 'donor_advised_fund',
  Offline = 'offline',
  Other = 'other'
}

export type TransactionalRevenueFilterInput = {
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TransactionalRevenueItem = {
  __typename?: 'TransactionalRevenueItem';
  date: Scalars['Date']['output'];
  donation: Scalars['Int']['output'];
  fee: Scalars['Int']['output'];
  tip: Scalars['Int']['output'];
  transactionId: Scalars['ID']['output'];
  type: Scalars['String']['output'];
  workspaceHubSpotConsultant?: Maybe<Scalars['String']['output']>;
  workspaceHubSpotOwnerId?: Maybe<Scalars['Int']['output']>;
  workspaceId: Scalars['ID']['output'];
};

export type TransactionalRevenueReportResult = {
  __typename?: 'TransactionalRevenueReportResult';
  pageInfo?: Maybe<PageInfo>;
  transactionalRevenueItems: Array<TransactionalRevenueItem>;
};

export type TwilioPhoneNumber = {
  __typename?: 'TwilioPhoneNumber';
  created: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  phone: Scalars['String']['output'];
};

export type UnarchiveCampaignInput = {
  id: Scalars['ID']['input'];
};

export type UnarchivePageInput = {
  id: Scalars['ID']['input'];
};

export type UnpublishPageInput = {
  id: Scalars['ID']['input'];
};

export type UnreadReplyCount = {
  __typename?: 'UnreadReplyCount';
  count: Scalars['Int']['output'];
  workspaceId: Scalars['ID']['output'];
};

export type UnreadReplyCountInput = {
  workspaceId: Scalars['ID']['input'];
};

export type UnscheduleCampaignInput = {
  id: Scalars['ID']['input'];
};

export type UnsubscribeInput = {
  campaignId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};

export type UntagContactInput = {
  contactId: Scalars['ID']['input'];
  tagId: Scalars['ID']['input'];
};

export type UpdateAllocationInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateAutomationInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  type: AutomationType;
  workspaceId: Scalars['ID']['input'];
};

export type UpdateAutomationSettingsInput = {
  logoWidthPct: Scalars['Int']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type UpdateCampaignInput = {
  campaignId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type UpdateCampaignPageInput = {
  campaignId: Scalars['ID']['input'];
  pageId: Scalars['ID']['input'];
};

export type UpdateChariotIntegrationInput = {
  chariotEnabled: Scalars['Boolean']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type UpdateCommentInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateContactInput = {
  address?: InputMaybe<ContactAddressInput>;
  emailStatus: EmailSubscriptionStatus;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  smsStatus: SmsSubscriptionStatus;
};

export type UpdateContactNotesInput = {
  id: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCurrentUserInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  profilePictureUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomDomainInput = {
  domain: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type UpdateDonateButtonInput = {
  color: Scalars['String']['input'];
  pageId?: InputMaybe<Scalars['ID']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type UpdateDonationTiersInput = {
  donationTiers: Array<DonationTierInput>;
  pageId: Scalars['ID']['input'];
};

export type UpdateEmailPopupInput = {
  color: Scalars['String']['input'];
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type UpdateEmbeddableDonationFormInput = {
  data: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  pageId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type UpdateEmbeddableDonationFormResult = {
  __typename?: 'UpdateEmbeddableDonationFormResult';
  id: Scalars['ID']['output'];
};

export type UpdateFacebookPixelIntegrationInput = {
  facebookPixelId?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type UpdateFundraiserInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  profilePictureUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGoalsInput = {
  goals: Array<GoalInput>;
  pageId: Scalars['ID']['input'];
};

export type UpdateGoogleAdsIntegrationInput = {
  googleAdsConversionId?: InputMaybe<Scalars['String']['input']>;
  googleAdsConversionLabel?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type UpdateGoogleAnalyticsIntegrationInput = {
  googleAnalyticsId?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type UpdateGoogleTagManagerIntegrationInput = {
  googleTagManagerId?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type UpdateInstitutionInput = {
  address?: InputMaybe<InstitutionAddressInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  type: InstitutionType;
};

export type UpdateInstitutionNotesInput = {
  id: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInvitationInput = {
  id: Scalars['ID']['input'];
  role: Role;
};

export type UpdateLogoInput = {
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type UpdateMailchimpAudienceInput = {
  mailchimpAudienceId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type UpdateMailchimpAudienceOutput = {
  __typename?: 'UpdateMailchimpAudienceOutput';
  success: Scalars['Boolean']['output'];
};

export type UpdateOfflineDonationInput = {
  allocationId?: InputMaybe<Scalars['ID']['input']>;
  amount: Scalars['Int']['input'];
  anonymous: Scalars['Boolean']['input'];
  date: OfflineDonationDateInput;
  dedication?: InputMaybe<DedicationInput>;
  fundraiserId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  nonDeductibleAmount: Scalars['Int']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  pageId: Scalars['ID']['input'];
  paymentMethodType: OfflinePaymentMethodType;
  pledge: Scalars['Boolean']['input'];
};

export type UpdatePageInput = {
  addressForm: Scalars['Boolean']['input'];
  allocations: Scalars['Boolean']['input'];
  chariotButton: Scalars['Boolean']['input'];
  color?: InputMaybe<Scalars['String']['input']>;
  communityTab: Scalars['Boolean']['input'];
  donationAmount: Scalars['Boolean']['input'];
  donorCoversFees: Scalars['Boolean']['input'];
  fundraisersTitle: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  peerToPeerTab: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  videoUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePageStoryInput = {
  pageId: Scalars['ID']['input'];
  story?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateReplyInput = {
  id: Scalars['ID']['input'];
  reply?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateReplyTaskResult = {
  __typename?: 'UpdateReplyTaskResult';
  replyTask: ReplyTask;
  unreadReplyCount: UnreadReplyCount;
};

export type UpdateSalesforceSettingsInput = {
  accountRecordType?: InputMaybe<Scalars['String']['input']>;
  campaignRecordType?: InputMaybe<Scalars['String']['input']>;
  contactRecordType?: InputMaybe<Scalars['String']['input']>;
  opportunityRecordType?: InputMaybe<Scalars['String']['input']>;
  stageName: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type UpdateSegmentInput = {
  conditions: Scalars['String']['input'];
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateSoftCreditInput = {
  orderId: Scalars['ID']['input'];
  softCredits: Array<SoftCreditAbstract>;
};

export type UpdateTagInput = {
  color: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateTimezoneInput = {
  timezone: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type UpdateTransactionNotesInput = {
  id: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTransactionPageInput = {
  fundraiserId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  pageId: Scalars['ID']['input'];
};

export type UpdateWorkspaceInput = {
  address: NonprofitAddressInput;
  emailSenderEmail?: InputMaybe<Scalars['String']['input']>;
  emailSenderName?: InputMaybe<Scalars['String']['input']>;
  facebookUrl?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  instagramUrl?: InputMaybe<Scalars['String']['input']>;
  linkedInUrl?: InputMaybe<Scalars['String']['input']>;
  nonprofitName: Scalars['String']['input'];
  statementDescriptor?: InputMaybe<Scalars['String']['input']>;
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  workspaceName: Scalars['String']['input'];
  youtubeUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkspacePrimaryContactInput = {
  userId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type UpsertEmbeddableDonationFormInput = {
  data: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  pageId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type UpsertEmbeddableDonationFormResult = {
  __typename?: 'UpsertEmbeddableDonationFormResult';
  id: Scalars['Int']['output'];
};

export enum UserState {
  Active = 'active',
  PendingRegistration = 'pending_registration'
}

export type UserSubscriptionStatus = {
  __typename?: 'UserSubscriptionStatus';
  email: Scalars['String']['output'];
  subscribed: Scalars['Boolean']['output'];
};

export type UtmData = {
  __typename?: 'UtmData';
  campaign?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  medium?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

export type UtmDataInput = {
  campaign?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  medium?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export enum UtmMedium {
  Email = 'email',
  Offline = 'offline',
  Other = 'other',
  Sms = 'sms',
  Social = 'social',
  Website = 'website'
}

export enum UtmSource {
  Appeal = 'appeal',
  Blog = 'blog',
  Cultivation = 'cultivation',
  DirectMail = 'directMail',
  Event = 'event',
  Facebook = 'facebook',
  FloatingDonationButton = 'floatingDonationButton',
  Homepage = 'homepage',
  Instagram = 'instagram',
  Kindest = 'kindest',
  Linkedin = 'linkedin',
  Newsletter = 'newsletter',
  Other = 'other',
  TextToDonate = 'textToDonate',
  Twitter = 'twitter'
}

export type ValidateCampaignInput = {
  id: Scalars['ID']['input'];
};

export type ValidateCampaignResult = {
  __typename?: 'ValidateCampaignResult';
  smsCampaignInformation: SmsCampaignInformation;
  success: Scalars['Boolean']['output'];
};

export type ValidateCancelProSubscriptionInput = {
  workspaceId: Scalars['ID']['input'];
};

export type ValidateCancelProSubscriptionResult = {
  __typename?: 'ValidateCancelProSubscriptionResult';
  enabledPaidFeatures: Array<PaidFeatures>;
};

export type ValidatePageInput = {
  id: Scalars['ID']['input'];
};

export type ValidatePageResult = {
  __typename?: 'ValidatePageResult';
  success: Scalars['Boolean']['output'];
};

export type VerifyBillingTokenInput = {
  billingPeriod: BillingPlanPeriodType;
  billingPlan: BillingPlanName;
  billingTokenId: Scalars['ID']['input'];
  cancelUrl: Scalars['String']['input'];
  successUrl: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type VerifyInvitationTokenInput = {
  token: Scalars['String']['input'];
  tokenId: Scalars['ID']['input'];
};

export type VerifyInvitationTokenResult = {
  __typename?: 'VerifyInvitationTokenResult';
  email: Scalars['String']['output'];
  existing: Scalars['Boolean']['output'];
  invitedBy: InvitedBy;
  workspaceName: Scalars['String']['output'];
};

export type VerifyPasswordResetTokenInput = {
  token: Scalars['String']['input'];
  tokenId: Scalars['ID']['input'];
};

export type VerifyPasswordResetTokenResult = {
  __typename?: 'VerifyPasswordResetTokenResult';
  success: Scalars['Boolean']['output'];
};

export type VerifyWorkspaceInput = {
  id: Scalars['ID']['input'];
  verified: Scalars['Boolean']['input'];
};

export type Weekday = {
  __typename?: 'Weekday';
  amount: Scalars['Int']['output'];
  weekday: DayOfWeek;
};

export type Workspace = {
  __typename?: 'Workspace';
  address: NonprofitAddress;
  billingCanceled?: Maybe<Scalars['Date']['output']>;
  billingPlan: BillingPlanName;
  billingStarted?: Maybe<Scalars['Date']['output']>;
  billingUnpaid?: Maybe<Scalars['Date']['output']>;
  chariotConnectId?: Maybe<Scalars['String']['output']>;
  chariotEnabled: Scalars['Boolean']['output'];
  constantContactConnected: Scalars['Boolean']['output'];
  constantContactListName?: Maybe<Scalars['String']['output']>;
  created: Scalars['Date']['output'];
  doubleTheDonationConnected: Scalars['Boolean']['output'];
  doubleTheDonationPublicKey?: Maybe<Scalars['String']['output']>;
  emailCampaignsEnabled: Scalars['Boolean']['output'];
  emailPopupEnabled: Scalars['Boolean']['output'];
  emailSenderEmail?: Maybe<Scalars['String']['output']>;
  emailSenderName?: Maybe<Scalars['String']['output']>;
  facebookPage?: Maybe<FacebookPage>;
  facebookPixelId?: Maybe<Scalars['String']['output']>;
  facebookUrl?: Maybe<Scalars['String']['output']>;
  googleAdsConversionId?: Maybe<Scalars['String']['output']>;
  googleAdsConversionLabel?: Maybe<Scalars['String']['output']>;
  googleAnalyticsId?: Maybe<Scalars['String']['output']>;
  googleTagManagerId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instagramUrl?: Maybe<Scalars['String']['output']>;
  linkedInUrl?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  mailchimpAudienceName?: Maybe<Scalars['String']['output']>;
  mailchimpConnected: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  needsStripeAccount: Scalars['Boolean']['output'];
  needsVerification: Scalars['Boolean']['output'];
  nonprofitName: Scalars['String']['output'];
  nonprofitTaxId: Scalars['String']['output'];
  primaryContact?: Maybe<WorkspaceMember>;
  salesforceAccountRecordTypeName?: Maybe<Scalars['String']['output']>;
  salesforceCampaignRecordTypeName?: Maybe<Scalars['String']['output']>;
  salesforceConnected: Scalars['Boolean']['output'];
  salesforceContactRecordTypeName?: Maybe<Scalars['String']['output']>;
  salesforceOpportunityRecordTypeName?: Maybe<Scalars['String']['output']>;
  salesforceStageName?: Maybe<Scalars['String']['output']>;
  smsCampaignsEnabled: Scalars['Boolean']['output'];
  statementDescriptor?: Maybe<Scalars['String']['output']>;
  stripeAccountConnected: Scalars['Boolean']['output'];
  stripeAccountType?: Maybe<StripeAccountType>;
  taxNote: Scalars['String']['output'];
  textToDonateEnabled: Scalars['Boolean']['output'];
  timezone: Scalars['String']['output'];
  tipNote: Scalars['String']['output'];
  twitterUrl?: Maybe<Scalars['String']['output']>;
  verified: Scalars['Boolean']['output'];
  website?: Maybe<Scalars['String']['output']>;
  youtubeUrl?: Maybe<Scalars['String']['output']>;
};

export type WorkspaceContactInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type WorkspaceList = {
  __typename?: 'WorkspaceList';
  pageInfo: WorkspaceListPageInfo;
  searchApiKey: Scalars['String']['output'];
  workspaces: Array<Workspace>;
};

export enum WorkspaceListOrderBy {
  Created = 'created',
  HasKindestPro = 'hasKindestPro',
  Name = 'name',
  NonprofitName = 'nonprofitName',
  Verified = 'verified'
}

export enum WorkspaceListOrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type WorkspaceListOrderInput = {
  by: WorkspaceListOrderBy;
  direction: WorkspaceListOrderDirection;
};

export type WorkspaceListPageInfo = {
  __typename?: 'WorkspaceListPageInfo';
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type WorkspaceListPageInput = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type WorkspaceMember = {
  __typename?: 'WorkspaceMember';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  primaryContact: Scalars['Boolean']['output'];
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
  role: Role;
};

export type WorkspaceRole = {
  __typename?: 'WorkspaceRole';
  role: Role;
  workspace: Workspace;
};
